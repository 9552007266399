import React from 'react'
import {
  TextField, Stack, Typography, Button, Card, MenuItem,
} from '@mui/material'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'

export default function RequestBloodForm({ showForm, setShowForm, saveUserHandler }) {

  const addUserSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email is required'),
    firstName: Yup.string()
      .required(' First name is required'),
    lastName: Yup.string()
      .required('Last name is required'),
    password: Yup.string()
      .required('Password is required'),
    role: Yup.string()
      .required('Role is required'),
  })
  const formik = useFormik({
    initialValues: {
      username: '',
      firstName: '',
      lastName: '',
      password: '',
      role: '',
    },
    validationSchema: addUserSchema,
    onSubmit: () => {
      saveUserHandler(values)
    }
  })

  const {
    errors, touched, handleSubmit, getFieldProps, values
  } = formik
  const fieldTextStyle = {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: "3px"
  }
  return (

    <Card sx={{ padding: '2rem 2rem 3rem 2rem', borderRadius: '5px' }}>
      <FormikProvider value={formik}>
        <Form
          id='form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack spacing={2} sx={{ width: "100%" }} direction={{ xs: "column", sm: "column", md: 'row' }} justifyContent='space-between' alignItems='center'>
            <Stack sx={{ width: "100%" }} >
              <Typography variant='body1' sx={fieldTextStyle}>First Name</Typography>
              <TextField
                fullWidth
                size='small'
                type='text'
                name='firstName'
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Last Name</Typography>
              <TextField
                fullWidth
                size='small'
                type='text'
                name='lastName'
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Role</Typography>
              <TextField
                fullWidth
                size='small'
                select
                type='text'
                name='role'
                {...getFieldProps('role')}
                error={Boolean(touched.role && errors.role)}
                helperText={touched.role && errors.role}
                sx={{ marginBottom: '10px ' }}
              >
                <MenuItem value='Admin'>Admin</MenuItem>
                <MenuItem value='Moderator'>Moderator</MenuItem>
              </TextField>
              <Typography variant='body1' sx={fieldTextStyle}>Email</Typography>
              <TextField
                fullWidth
                size='small'
                type='text'
                name='username'
                {...getFieldProps('username')}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Password</Typography>
              <TextField
                fullWidth
                size='small'
                type='password'
                name='password'
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                sx={{ marginBottom: '10px ' }}
              />
            </Stack>
          </Stack>
          <Stack mt={4} direction='row' spacing={1}>
            <Button type='submit' fullWidth color='secondary' variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Save</Button>
            <Button onClick={() => setShowForm(!showForm)} type='submit' fullWidth variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Cancel</Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  )
}
