import React from "react";
import { Stack, Typography, Container } from "@mui/material";
import styled from "styled-components"

const BannerImg = styled(Container)({
  backgroundImage: "linear-gradient(0deg, rgba(137, 24, 28, 0.6), rgba(137, 24, 28, 0.6)), url(./assets/contact/contactPageBanner.svg)",
  height: "40vh",
  width: "100%",
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '@media(min-width:320px) and (max-width:900px)': {
    marginTop: "0px"
  }
})

const Banner = ({ BannerTitle }) => {
  return (
    <BannerImg maxWidth='100%'>
      <Stack sx={{ height: "100% !important" }} justifyContent="center" alignItems="center" >
        <Typography sx={{ fontSize: '2.2rem', fontWeight: 700, textTransform: "uppercase", color: "#fff" }}>{BannerTitle}</Typography>
      </Stack>
    </BannerImg>
  )
}

export default Banner