import { Stack, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const CenterHeadingContainer = styled(Stack)({
  '@media(min-width:320px) and (max-width:900px)': {
    padding: "0px 0px !important"
  }
})
const Heading = styled(Typography)({
  '@media(min-width:320px) and (max-width:550px)': {
    fontSize: "1.5rem",
    padding:"0px"
  },
  '@media(min-width:551px) and (max-width:900px)': {
    fontSize: "2rem"
  }
})
const CenterHeading = ({ heading }) => {
  return (
    <CenterHeadingContainer justifyContent='center' alignItems='center' sx={{ padding: "0px 100px !important" }}>
      <Heading variant='h2' sx={{ padding: "0px 100px", fontSize: "2.2rem", fontWeight: 600, textAlign: "center", textTransform: "uppercase !important" }}>{heading}</Heading>
    </CenterHeadingContainer>
  )
}

export default CenterHeading