import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Container, Grid } from '@mui/material'
import ProjectForm from '../components/projects/projectForm'
import { SAVE_PROJECT } from '../shared/constants/APIs'
import Loading from '../components/loading'

// ***********************************************
const Addproject = () => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false)
  const saveProjectHandler = async (values) => {
    setShowSpinner(true)
    await axios.post(SAVE_PROJECT, values)
      .then((response) => {
        setShowSpinner(false)
        if(response){}
      }, (error) => {
        if (error) {
          navigate('/login')
        }
        setShowSpinner(false)
        console.log(error)
      })
  }
  return (
    <Container sx={{ marginTop: "150px" }}>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={5}>
          <ProjectForm {...{ saveProjectHandler }} />
          <Loading {...{ showSpinner }} />
        </Grid>
      </Grid>

    </Container>
  )
}

export default Addproject