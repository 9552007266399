import React from 'react'
import styled from 'styled-components'
import { Container, Grid, Stack, Typography } from '@mui/material'
import { Icon } from '@iconify/react'
import { bloodRequestBullets } from '../shared/bloodRequestBullets'
import HeartAndline from './heartAndline'
import {
  BDAP_BLOOD_REQUEST_CRITERIA,
  BLOOD_REQUEST_CRITERIA_PARRA,
} from '../shared/constants/home'
const BloodRequestContainer = styled(Container)({
  backgroundImage: "linear-gradient(0deg, rgba(217, 217, 217, 0.8), rgba(217, 217, 217, 0.8)), url(./assets/home/bloodRequest.svg)",
  height: "50vh",
  width: "100%",
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  paddingBottom: "20px",
  '@media(min-width:320px) and (max-width:900px)': {
    backgroundSize: 'cover',
    height: "fit-content",
  },
  '@media(min-width:901px) and (max-width:1330px)': {
    backgroundSize: 'cover',
    height: "fit-content",
  }
})
const Bullets = styled(Stack)({
  backgroundColor: "#89181C",
  width: "290px",
  padding: "15px 20px 15px 30px",
  borderRadius: "5px"
})
const BloodRequestHeading = styled(Typography)({
  '@media(min-width:320px) and (max-width:580px)': {
    fontSize: "1.5rem"
  },
  '@media(min-width:581px) and (max-width:1080px)': {
    fontSize: "1.7rem"
  }
})
const BloodRequestPara = styled(Typography)({
  '@media(min-width:320px) and (max-width:1080px)': {
    fontSize: "1rem"
  }
})
const BloodRequest = () => {
  return (
    <BloodRequestContainer maxWidth='100%' sx={{ marginTop: "100px" }}>
      <Container sx={{ height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} sm={12} md={6}>
            <Stack justifyContent='center' alignItems='flex-start' mt={5}>
              <HeartAndline />
              <Stack mt={2} spacing={1}>
                <BloodRequestHeading variant='h2' sx={{ fontWeight: 700, fontSize: '2.1rem', textTransform: 'upperCase !important', }}>{BDAP_BLOOD_REQUEST_CRITERIA}</BloodRequestHeading>
                <BloodRequestPara variant='body1'   sx={{ fontWeight: 200, fontSize: '1.2rem' }}>
                  {BLOOD_REQUEST_CRITERIA_PARRA}
                </BloodRequestPara>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stack justifyContent='center' alignItems={{ xs: "center", sm: "center", md: "flex-end" }} sx={{ height: "100%" }}>
              {bloodRequestBullets.map((item, index) => (
                <Stack mt={2} key={index} justifyContent='center' alignItems='flex-end' >
                  <Bullets direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='subtitle1' sx={{ color: "#fff", fontWeight: 600, fontSize: "1rem", textTransform: 'capitalize', textAlign: "center" }}>{item.title}</Typography>
                    <Icon icon="ic:round-arrow-forward-ios" color="white" />
                  </Bullets>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </BloodRequestContainer>
  )
}

export default BloodRequest