export const saveLives = [
  {
    img: "./assets/home/handHoldingHeart.svg",
    title: "WE Donate",
    content: "Donate expertise, Donations, Food Bags, and equipments to less privileged."
  },
  {
    img: "./assets/home/weVolunteer.svg",
    title: "WE Volunteer",
    content: "Voluntary, non-remunerated blood donation."
  },
  {
    img: "./assets/home/weEncourage.svg",
    title: "WE Encourage",
    content: "The blood banking community to share resources."
  }
]