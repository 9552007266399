import React from 'react'
import { Box, Button, CardMedia, Container, Grid, Stack, Typography } from '@mui/material'
import HeartAndline from './heartAndline'
import {
  OTHER_CASES,
  BLOOD_CASES_SOLVED,
  FOOD_BAGS_DELIVERED,
  BLOOD_DONORS_ACROSS_PAKISTAN_HAS_STARTED_FEMALES,
  TOGETHER_WE_CAN_DELIVER_HEALTH_AND_HOPE,
} from '../shared/constants/home'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
const WeCanDeliver = () => {
  const CardStyles = {
    backgroundColor: "#89181C",
    color: "#fff",
    textAlign: "center",
    padding: "40px 10px",
    borderRadius: "5px"
  }
  const ImgStyles = styled(CardMedia)({
    '@media(min-width:320px) and (max-width:900px)': {
      height: "250px"
    }
  })
  const GridCard = styled(Grid)({
    paddingLeft: "40px",
    '@media(min-width:320px) and (max-width:900px)': {
      paddingLeft: "0px"
    }
  })
  const CanWeDeliverHeading = styled(Typography)({
    '@media(min-width:320px) and (max-width:550px)': {
      fontSize: "1.5rem"
    }
  })
  const GridCardHeading = styled(Typography)({
    '@media(min-width:900px) and (max-width:950px)': {
      fontSize: ".9rem"
    },
    '@media(min-width:951px) and (max-width:1060px)': {
      fontSize: "1rem"
    },
  })
  return (
    <Container sx={{ marginTop: "100px" }}>
      <Grid container justifyContent='flex-start' alignItems='flex-start'>
        <Grid item xs={12} sm={12} md={4}>
          <ImgStyles
            component="img"
            image="./assets/home/canWeDeliver.svg"
            alt="nav image"
            sx={{ width: 'fit-content', height: '400px' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Stack justifyContent='flex-start' alignItems='flex-start' spacing={2} ml={{ xs: 0, sm: 0, md: 5 }} sx={{ pr: 4 }}>
            <HeartAndline />
            <CanWeDeliverHeading variant='h2' sx={{ fontSize: "2rem", textTransform: "uppercase", fontWeight: 700, paddingRight: "20px" }}>{TOGETHER_WE_CAN_DELIVER_HEALTH_AND_HOPE}</CanWeDeliverHeading>
            <Typography variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}>{BLOOD_DONORS_ACROSS_PAKISTAN_HAS_STARTED_FEMALES}</Typography>
            <Button component={RouterLink} to='our-projects' variant='contained' sx={{ textTransform: "uppercase", padding: "10px 20px", fontWeight: 700 }}>see our impact</Button>
          </Stack>
        </Grid>
      </Grid>
      <Container>
        <Grid container sx={{ marginTop: '40px' }} spacing={2.3} justifyContent='center' alignItems='center'>
          <GridCard item xs={12} sm={8} md={4}>
            <Box sx={CardStyles}>
              <Typography variant='h5' sx={{ fontSize: '3rem', fontWeight: 700 }}>21,000+</Typography>
              <GridCardHeading variant='h2' sx={{ fontSize: '1.2rem', fontWeight: 600, textTransform: "uppercase", }}>{BLOOD_CASES_SOLVED}</GridCardHeading>
            </Box>
          </GridCard>
          <GridCard item xs={12} sm={8} md={4}>
            <Box sx={CardStyles}>
              <Typography variant='h5' sx={{ fontSize: '3rem', fontWeight: 700 }}>3500+</Typography>
              <GridCardHeading variant='h2' sx={{ fontSize: '1.2rem', fontWeight: 600, textTransform: "uppercase", }}>{FOOD_BAGS_DELIVERED}</GridCardHeading>
            </Box>
          </GridCard>
          <GridCard item xs={12} sm={8} md={4}>
            <Box sx={CardStyles}>
              <Typography variant='h5' sx={{ fontSize: '3rem', fontWeight: 700 }}>350+</Typography>
              <GridCardHeading variant='h2' sx={{ fontSize: '1.2rem', fontWeight: 600, textTransform: "uppercase", }}>{OTHER_CASES}</GridCardHeading>
            </Box>
          </GridCard>
        </Grid>
      </Container>
    </Container>
  )
}

export default WeCanDeliver