import React from 'react'
import { Stack, Grid, Button, Chip } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Search from '../search'
import CenterHeading from '../centerHeading'

// *******************************************************************************
const BloodRequest = ({ bloodRequests, LoadMoreHandler, searchHandler, hideLoadButton }) => {
  return (
    <>
      <Stack justifyContent='center' alignItems='center' mb={2}>
        <CenterHeading {...{ heading: "Blood requests" }} />
      </Stack>
      <Stack sx={{ maxWidth: 485, marginBottom: "30px" }}>
        <Search {...{ placeholder: "Search with blood group or city", searchHandler }} />
      </Stack>
      <Grid container spacing={4}>
        {bloodRequests && bloodRequests?.length > 0 && bloodRequests.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card sx={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', borderTop: "solid 3px #89181C", cursor: 'pointer', borderRadius: "0px !important" }}>
              <CardContent>
                <Stack spacing={2}>
                  <Stack direction='row' justifyContent='space-between' spacing={2} alignItems='center'>
                    <Stack direction='row' spacing={2}>
                      <Typography variant='subtitle1' sx={{ fontSize: "14px", fontWeight: 600 }}>
                        Blood Group
                      </Typography>
                      <Typography sx={{ fontSize: 15 }} color="text.secondary">
                        {item.bloodGroup}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Chip label={item.status} color='primary' />
                    </Stack>
                  </Stack>
                  <Stack direction='row' justifyContent='flex-start' spacing={2} alignItems='center'>
                    <Typography variant='subtitle1' sx={{ fontSize: "14px", fontWeight: 600 }}>
                      Hospital
                    </Typography>
                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                      {item.hospital}
                    </Typography>
                  </Stack>
                  <Stack direction='row' justifyContent='flex-start' spacing={2} alignItems='center'>
                    <Typography variant='subtitle1' sx={{ fontSize: "14px", fontWeight: 600 }}>
                      City
                    </Typography>
                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                      {item.city}
                    </Typography>
                  </Stack>
                  <Stack direction='row' justifyContent='flex-start' spacing={2} alignItems='center'>
                    <Typography variant='subtitle1' sx={{ fontSize: "14px", fontWeight: 600 }}>
                      Name
                    </Typography>
                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                      {item.contactPersonName}
                    </Typography>
                  </Stack>
                  <Stack direction='row' justifyContent='flex-start' spacing={2} alignItems='center'>
                    <Typography variant='subtitle1' sx={{ fontSize: "14px", fontWeight: 600 }}>
                      Contact Number
                    </Typography>
                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                      {item.contactNumber}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))
        }
        {!hideLoadButton && bloodRequests?.length > 0 && <Stack sx={{ width: "100%" }} justifyContent='center' alignItems='center' mt={8}>
          <Button onClick={LoadMoreHandler} sx={{ padding: "10px 40px" }} variant='contained'>Load More</Button>
        </Stack>}
      </Grid>
    </>
  )
}

export default BloodRequest