import React, { useState } from 'react'
import {
  TextField, Stack, Typography, Button, Card
} from '@mui/material'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { BLOOD_REQUIEST } from '../../shared/constants/APIs'
import Loading from '../loading'


export default function RequestBloodForm() {
  const [showSpinner, setShowSpinner] = useState(false)
  const [message, setMessage] = useState('')
  const RequestBloodSchema = Yup.object().shape({
    hospital: Yup.string()
      .required('Hospital Name is required'),
    bloodGroup: Yup.string()
      .required('Patient blood group is required'),
    contact: Yup.string()
      .required('Contact number is required'),
    city: Yup.string()
      .required('City is required'),
    cnic: Yup.string()
      .required('CNIC is required'),
    contactPersonName: Yup.string()
      .required('Contact person name is required'),
    contactPersonBloodGroup: Yup.string()
      .required('Your blood group is required')
  })
  const formik = useFormik({
    initialValues: {
      hospital: '',
      bloodGroup: '',
      contact: '',
      city: '',
      cnic: '',
      contactPersonName: '',
      contactPersonBloodGroup: '',
    },
    validationSchema: RequestBloodSchema,
    onSubmit: async () => {
      setShowSpinner(true)
      await axios.post(BLOOD_REQUIEST, values)
        .then((response) => {
          if(response){}
          setShowSpinner(false)
          resetForm()
          setMessage('Thank you for submitting the blood request, our team will contact you shortly.')

        }, (error) => {
          setShowSpinner(false)
          console.log(error)
        })
    }
  })

  const { errors, touched, handleSubmit, getFieldProps, values, resetForm } = formik
  const fieldTextStyle = {
    fontSize: '16px',
    fontWeight: 500
  }
  return (
    <Stack justifyContent='center' alignItems='center'>
      <Card sx={{ maxWidth: 700, padding: '30px' }}>
        <Stack mb={1}>
          <Typography variant='h4' sx={{ fontSize: "1.8rem", fontWeight: 700, textAlign: "center" }}>Blood Request</Typography>
        </Stack>
        {message !== '' && <Stack mb={2} mt={2}>
          <Typography sx={{ color: "green", fontSize: '1rem', fontWeight: 400 }}>{message}</Typography>
        </Stack>}
        <FormikProvider value={formik}>
          <Form
            id='form'
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <Typography variant='body1' sx={fieldTextStyle}>Hospital</Typography>
            <TextField
              fullWidth
              variant="outlined"
              size='medium'
              type='text'
              name='hospital'
              {...getFieldProps('hospital')}
              error={Boolean(touched.hospital && errors.hospital)}
              helperText={touched.hospital && errors.hospital}
              sx={{ marginBottom: '10px ' }}

            />
            <Typography variant='body1' sx={fieldTextStyle}>City</Typography>
            <TextField
              fullWidth
              size='medium'
              type='text'
              name='city'
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
              sx={{ marginBottom: '10px ' }}
            />
            <Stack spacing={2} sx={{ width: "100%" }} direction={{ xs: "column", sm: "column", md: 'row' }} justifyContent='space-between' alignItems='center'>
              <Stack sx={{ width: "100%" }} >
                <Typography variant='body1' sx={fieldTextStyle}>Patient Blood Group</Typography>
                <TextField
                  fullWidth
                  size='medium'
                  type='text'
                  name='bloodGroup'
                  {...getFieldProps('bloodGroup')}
                  error={Boolean(touched.bloodGroup && errors.bloodGroup)}
                  helperText={touched.bloodGroup && errors.bloodGroup}
                  sx={{ marginBottom: '10px ' }}
                />
              </Stack>
              <Stack sx={{ width: "100%" }} >
                <Typography variant='body1' sx={fieldTextStyle}>Your Blood Group</Typography>
                <TextField
                  fullWidth
                  size='medium'
                  type='text'
                  name='contactPersonBloodGroup'
                  {...getFieldProps('contactPersonBloodGroup')}
                  error={Boolean(touched.contactPersonBloodGroup && errors.contactPersonBloodGroup)}
                  helperText={touched.contactPersonBloodGroup && errors.contactPersonBloodGroup}
                  sx={{ marginBottom: '10px ' }}
                />
              </Stack>
            </Stack>

            <Typography variant='body1' sx={fieldTextStyle}>Contact Person Name</Typography>
            <TextField
              fullWidth
              size='medium'
              type='text'
              name='contactPersonName'
              {...getFieldProps('contactPersonName')}
              error={Boolean(touched.contactPersonName && errors.contactPersonName)}
              helperText={touched.contactPersonName && errors.contactPersonName}
              sx={{ marginBottom: '10px ' }}
            />
            <Stack spacing={2} sx={{ width: "100%" }} direction={{ xs: "column", sm: "column", md: 'row' }} justifyContent='space-between' alignItems='center'>
              <Stack sx={{ width: "100%" }}>
                <Typography variant='body1' sx={fieldTextStyle}>Contact Number</Typography>
                <TextField
                  fullWidth
                  size='medium'
                  type='text'
                  name='contact'
                  {...getFieldProps('contact')}
                  error={Boolean(touched.contact && errors.contact)}
                  helperText={touched.contact && errors.contact}
                  sx={{ marginBottom: '10px ' }}
                />
              </Stack>
              <Stack sx={{ width: "100%" }}>
                <Typography variant='body1' sx={fieldTextStyle}>CNIC</Typography>
                <TextField
                  fullWidth
                  size='medium'
                  type='text'
                  name='cnic'
                  {...getFieldProps('cnic')}
                  error={Boolean(touched.cnic && errors.cnic)}
                  helperText={touched.cnic && errors.cnic}
                  sx={{ marginBottom: '10px ' }}
                />
              </Stack>
            </Stack>
            <Stack mt={4}>
              <Button type='submit' fullWidth variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Submit Request</Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Card>
      <Loading {...{ showSpinner }} />
    </Stack>

  )
}
