import React from 'react'
import { Typography } from '@mui/material'

export default function EmptyTable({ message }) {
  return (
    <>
      <Typography sx={{ fontSize: '1.2rem' }} gutterBottom align='center' variant='subtitle1'>
        {message}
      </Typography>
    </>
  )
}
