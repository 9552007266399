export const bloodRequestBullets = [
  {
    title:'confirmed blood group'
  },
  {
    title:'confirmed hospital/lab'
  },
  {
    title:'confirmed city/location'
  },
  {
    title:'confirmed attendant contact'
  },
]