import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditForm from './editForm'
import { Grid, Stack } from '@mui/material';
import Search from '../../search';
import MenuAction from '../menuItem'
import EmptyTable from '../../emptyTable';
// ******************************************************

const columns = [
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'bloodGroup', label: 'Blood Group', minWidth: 100 },
  {
    id: 'city',
    label: 'City',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'contact',
    label: 'Contact',
    minWidth: 100,
    align: 'left'
  },

  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'donate',
    label: 'Last Blood Donate On',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'action',
    align: 'right'
  },


];
export default function DonorsTable({ setPage, page, totalCount, setShowFormDialog, showFormDialog, donors, searchHandler, LoadMoreHandler, deleteHandler, editDonorHandler }) {
  ;
  const rowsPerPage = 10
  const [donorData, setDonorData] = useState()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    LoadMoreHandler()

  };


  const editHandler = (donor) => {
    setShowFormDialog(true)
    setDonorData(donor)
  }

  return (
    <>
      {
        !showFormDialog
          ? <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', padding: "20px 30px" }}>
            <Stack sx={{ maxWidth: 440, marginBottom: "30px", transition: "all 0.3s" }}>
              <Search {...{ placeholder: "Search with blood group or city", searchHandler }} />
            </Stack>
            <TableContainer sx={{}}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {donors && donors?.length > 0 && donors
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover role="checkbox"
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                        // onClick={() => editHandler(row)}
                        >
                          <TableCell component="th" scope="row">
                            {row.fullName}
                          </TableCell>
                          <TableCell align="left">{row.bloodGroup}</TableCell>
                          <TableCell align="left">{row.currentCity}</TableCell>
                          <TableCell align='left'>{row.contactNumber}</TableCell>
                          <TableCell align='left'>{row.status}</TableCell>
                          <TableCell align='left'>{row.donateOn}</TableCell>
                          <TableCell><MenuAction {...{ editHandler, row, deleteHandler }} /></TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                {donors?.length === 0 && (
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                        <EmptyTable message='No blood request found' />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions=''
              component="div"
              count={totalCount && totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </Paper> : <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} sm={12} md={6}>
              <EditForm {...{ setShowFormDialog, showFormDialog, donorData, editDonorHandler }} />
            </Grid>
          </Grid>}
    </>
  );
}
