import React from 'react'
import BloodRequest from '../components/bloodRequest'
import Page from '../components/helmet'
import Banner from '../components/home/banner'
import JoinMovement from '../components/joinMovement'
import WeCanDeliver from '../components/weCanDeliver'
import WeSaveLives from '../components/weSaveLives'
const Home = () => {
  return (
    <Page title='BDAP'>
      <Banner />
      <WeSaveLives />
      <BloodRequest />
      <WeCanDeliver />
      <JoinMovement />
    </Page>
  )
}

export default Home
