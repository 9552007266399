import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'
import MenuAction from '../menuItem';
import { Grid, Stack, Typography } from '@mui/material';
import AddUserForm from './addUserForm'
// ******************************************************

const columns = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'role',
    label: 'Role'
  },
  {
    id: 'createdOn',
    label: 'Created On'
  },
  {
    id: 'action',
    label: 'Actions'
  }
];
export default function UserTable({ setPage, page, allUser, LoadMoreHandler, totalCount, deleteHandler, saveUserHandler, setShowForm, showForm }) {
  const rowsPerPage = 10
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    LoadMoreHandler()
  };

  return (
    <div>
      <Stack justifyContent='flex-end' alignItems='flex-end' mb={2}>
        <Typography onClick={() => setShowForm(true)} variant='h6' sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#89181c", cursor: 'pointer' }}>+ Add User</Typography>
      </Stack>
      {!showForm ?
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', padding: "20px 30px" }}>
          <TableContainer sx={{}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, overflow: "hidden !important" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allUser && allUser?.length > 0 && allUser
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover role="checkbox"
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", overflow: "hidden !important" }}
                      >
                        <TableCell sx={{ maxWidth: 200 }}> {row.firstName}</TableCell>
                        <TableCell sx={{
                          whiteSpace: 'nowrap',
                          maxWidth: '350px',
                          overflow: 'hidden',
                          textOverflow: "ellipsis !important",
                        }}>{row.lastName}</TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell sx={{ minWidth: 100 }}>{row.role}</TableCell>
                        <TableCell sx={{ minWidth: 100 }}>{new Date(row.createdOn).toDateString()}</TableCell>
                        <TableCell ><MenuAction {...{ row, deleteHandler, index, hideEditAction: true }} /></TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions=''
            component="div"
            count={totalCount && totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </Paper> :
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={12} md={6}>
            <AddUserForm {...{ setShowForm, showForm, saveUserHandler }} />
          </Grid>
        </Grid>
      }

    </div>
  );
}