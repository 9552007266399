import React from 'react'
import Banner from '../components/banner'
import WeLoveTo from '../components/contact/weLoveTo'
import Page from '../components/helmet'
const Contact = () => {
  return (
    <Page title='BDAP | contact'>
      <Banner {...{ BannerTitle: "contact us" }} />
      <WeLoveTo />
    </Page>
  )
}

export default Contact

