import React from 'react'
import {
  TextField, Stack, Typography, Button, Card,
} from '@mui/material'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'

export default function RequestBloodForm({ showFormDialog, setShowFormDialog, donorData, editBloodRequestHandler }) {
  const editBloodRequestSchema = Yup.object().shape({
    hospital: Yup.string()
      .required('Hospital Name is required'),
    bloodGroup: Yup.string()
      .required('Blood group is required'),
    contact: Yup.string()
      .required('Contact number is required'),
    city: Yup.string()
      .required('City name is required'),
    contactPersonName: Yup.string()
      .required('Hospital name is required'),
    cnic: Yup.string()
      .required('CNIC is required'),
    status: Yup.string()
      .required('Status is required')
  })
  const formik = useFormik({
    initialValues: {
      hospital: donorData && donorData.hospital,
      bloodGroup: donorData && donorData.bloodGroup,
      city: donorData && donorData.city,
      contactPersonName: donorData && donorData.contactPersonName,
      contact: donorData && donorData.contactNumber,
      cnic: donorData && donorData.cnic,
      status: donorData && donorData.status
    },
    validationSchema: editBloodRequestSchema,
    onSubmit: () => {
      editBloodRequestHandler(values, donorData.id)
    }
  })

  const {
    errors, touched, handleSubmit, getFieldProps, values
  } = formik
  const fieldTextStyle = {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: "3px"
  }
  return (
    <Card sx={{ padding: '2rem 2rem 3rem 2rem', borderRadius: '5px' }}>
      <Stack mb={1}>
        <Typography variant='h4' sx={{ fontSize: "1.8rem", fontWeight: 600, textAlign: "center" }}>Edit Blood Request</Typography>
      </Stack>
      <FormikProvider value={formik}>
        <Form
          id='form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack spacing={2} sx={{ width: "100%" }} direction={{ xs: "column", sm: "column", md: 'row' }} justifyContent='space-between' alignItems='center'>
            <Stack sx={{ width: "100%" }} >
              <Typography variant='body1' sx={fieldTextStyle}>Hospital</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='hospital'
                {...getFieldProps('hospital')}
                error={Boolean(touched.hospital && errors.hospital)}
                helperText={touched.hospital && errors.hospital}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Contact Person Name</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='contactPersonName'
                {...getFieldProps('contactPersonName')}
                error={Boolean(touched.contactPersonName && errors.contactPersonName)}
                helperText={touched.contactPersonName && errors.contactPersonName}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Blood group</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='bloodGroup'
                {...getFieldProps('bloodGroup')}
                error={Boolean(touched.bloodGroup && errors.bloodGroup)}
                helperText={touched.bloodGroup && errors.bloodGroup}
                sx={{ marginBottom: '10px ' }}
              />

            </Stack>

          </Stack>
          <Stack sx={{ width: "100%" }} >
            <Typography variant='body1' sx={fieldTextStyle}>City</Typography>
            <TextField
              fullWidth
              size='medium'
              type='text'
              name='city'
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
              sx={{ marginBottom: '10px ' }}
            />
          </Stack>
          <Typography variant='body1' sx={fieldTextStyle}>Contact Number</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='contact'
            {...getFieldProps('contact')}
            error={Boolean(touched.contact && errors.contact)}
            helperText={touched.contact && errors.contact}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>CNIC</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='cnic'
            {...getFieldProps('cnic')}
            error={Boolean(touched.cnic && errors.cnic)}
            helperText={touched.cnic && errors.cnic}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Status</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='status'
            {...getFieldProps('status')}
            error={Boolean(touched.status && errors.status)}
            helperText={touched.status && errors.status}
            sx={{ marginBottom: '10px ' }}
          />

          <Stack mt={4} direction='row' spacing={1}>
            <Button type='submit' fullWidth color='secondary' variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Save</Button>
            <Button onClick={() => setShowFormDialog(!showFormDialog)} type='submit' fullWidth variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Cancel</Button>
          </Stack>

        </Form>
      </FormikProvider>
    </Card>


  )
}
