import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Container, Stack, Typography } from "@mui/material"
import Loading from '../components/loading'
import { GET_ADMIN_USERS, SAVE_ADMIN_USERS, DELETE_ADMIN_USERS } from '../shared/constants/APIs'
import UserTable from "../components/admin/user/userTable"

axios.defaults.withCredentials = true;
const User = () => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false)
  const [allUser, setAllUser] = useState([])
  const [showForm, setShowForm] = useState(false);
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(0)

  useEffect(() => {
    const getUsers = async () => {
      setShowSpinner(true)
      await axios.post(GET_ADMIN_USERS)
        .then((response) => {
          setAllUser([...response.data.data])
          setTotalCount(response.data.total)
          setShowSpinner(false)
        }, (error) => {
          if (error) {
            navigate('/login')
          }
          setShowSpinner(false)
          console.log(error)
        })
    }
    getUsers()
  }, [navigate])

  const LoadMoreHandler = async () => {
    setShowSpinner(true)
    const lastItem = allUser.slice(-1)
    const params = {
      startAt: lastItem[0].id
      // search: searchValue === '' ? undefined : searchValue
    }
    await axios.post(GET_ADMIN_USERS, params)
      .then((response) => {
        setShowSpinner(false)
        if (response.data.data.length === 0) {
          // setHideLoadButton(true)
        } else {
          setAllUser([...allUser, ...response.data.data])
        }
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  const refreshUsers = async () => {
    setShowSpinner(true)
    await axios.post(GET_ADMIN_USERS)
      .then((response) => {
        setAllUser([...response.data.data])
        setTotalCount(response.data.total)
        setShowSpinner(false)
      }, (error) => {
        if (error) {
          navigate('/login')
        }
        setShowSpinner(false)
        console.log(error)
      })
    setPage(0)
  }
  const deleteHandler = async (item) => {
    setShowSpinner(true)
    const { id } = item
    const params = { id }
    await axios.delete(DELETE_ADMIN_USERS, { data: params })
      .then((response) => {
        setShowSpinner(false)
        if (response) { }
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
    setAllUser([])
    refreshUsers()
    setShowForm(false)
  }
  const saveUserHandler = async (values) => {
    await axios.post(SAVE_ADMIN_USERS, values)
      .then((response) => {
        setShowSpinner(false)
        console.log(response.data)
      }, (error) => {
        setShowSpinner(false)
        console.log(error)
      })
    setAllUser([])
    refreshUsers()
    setShowForm(false)
  }
  return (
    <div>
      <Container maxWidth='lg' sx={{ marginTop: "40px" }}>
        <Stack justifyContent='center' alignItems='center' mb={2}>
          <Typography variant='h6' sx={{ fontSize: "2rem" }}>Users</Typography>
        </Stack>
        <UserTable {...{ page, setPage, saveUserHandler, allUser, LoadMoreHandler, totalCount, deleteHandler, setShowForm, showForm }} />
        <Loading {...{ showSpinner }} />
      </Container>
    </div>
  )
}

export default User