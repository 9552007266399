import React from 'react'
import { Grid, Card, CardContent, CardMedia, Stack, Typography, CardHeader, Avatar, Button } from '@mui/material'
import { red, pink, grey, purple, indigo, brown, green, deepOrange, } from '@mui/material/colors'

const Projects = ({ allProjects, hideLoadButton, LoadMoreHandler }) => {
  const color = [red[500], pink[500], grey[500], purple[500], indigo[500], brown[500], green[500], deepOrange[500]]
  return (
    <>
      <Grid container spacing={2} justifyContent='center' alignItems='z'>
        {allProjects && allProjects?.length > 0 && allProjects.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Stack mt={2}>
              <Card sx={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', cursor: 'pointer', borderRadius: "5px !important" }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: color[Math.floor(Math.random() * color.length)] }} aria-label="compaign">
                      {item.title.substring(0, 1)}
                    </Avatar>
                  }
                  title={item.title}
                  subheader={new Date(item.startDate).toDateString()}
                />
                <CardMedia
                  sx={{ height: 400, width: "100%", objectFit: 'contain' }}
                  image={item.imageUrl}
                  component="img"
                  title="Project Image"
                />
                <CardContent>
                  <Typography variant="body2">
                    {item.description}
                  </Typography>
                  <Stack mt={2} direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography variant='subtitle1' color='secondary' sx={{ fontWeight: 700 }}>Status</Typography>
                    <Typography variant='body1' sx={{ fontSize: '.9rem' }}>{item.status}</Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        ))}
        {!hideLoadButton && allProjects?.length > 0 && <Stack sx={{ width: "100%" }} justifyContent='center' alignItems='center' mt={8}>
          <Button onClick={LoadMoreHandler} sx={{ padding: "10px 40px" }} variant='contained'>Load More</Button>
        </Stack>}
      </Grid>
    </>
  )
}

export default Projects