import React, { useEffect, useState } from 'react'
import Projects from '../components/projects/projects'
import Page from '../components/helmet'
import { Container } from '@mui/material'
import Banner from '../components/banner'
import axios from 'axios'
import JoinMovement from '../components/joinMovement'
import { GET_PROJECTS } from '../shared/constants/APIs'
import Loading from '../components/loading'

const OurProjects = () => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [allProjects, setAllProjects] = useState([])
  const [hideLoadButton, setHideLoadButton] = useState(false)
  useEffect(() => {
    const getAllProjects = async () => {
      setShowSpinner(true)
      await axios.post(GET_PROJECTS)
        .then((response) => {
          setShowSpinner(false)
          setAllProjects([...response.data.data])
        }, (error) => {
          setShowSpinner(false)
          console.log(error)
        })
    }
    getAllProjects()
  }, [])

  const LoadMoreHandler = async () => {
    setShowSpinner(true)
    const lastItem = allProjects.slice(-1)
    const params = {
      startAt: lastItem[0].id
    }
    await axios.post(GET_PROJECTS, params)
      .then((response) => {
        setShowSpinner(false)
        console.log(response)
        if (response.data.data.length === 0) {
          setHideLoadButton(true)
        } else {
          setAllProjects([...allProjects, ...response.data.data])
        }
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  return (
    <Page title='BDAP | Our Projects'>
      <Banner {...{ BannerTitle: "Our projects" }} />
      <Container maxWidth='lg' sx={{ marginTop: "80px" }}>
        <Projects {...{ allProjects, hideLoadButton, LoadMoreHandler }} />
      </Container>
      <Loading {...{ showSpinner }} />
      <JoinMovement />
    </Page>
  )
}

export default OurProjects