import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField, Stack, Button } from '@mui/material'
import { Icon } from '@iconify/react'
const Search = ({ placeholder, searchHandler }) => {
  const [searchValue, setSearchValue] = useState('')
  const keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      searchHandler(searchValue)
    }
  }
  return (
    <Stack sx={{ width: "100%" }} direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
      <TextField
        fullWidth
        placeholder={placeholder}
        variant="standard"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyDown={keyDownHandler}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton edge='end' onClick={() => setSearchValue('')}>
                <Icon icon="ic:baseline-search" />
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={{ boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px', backgroundColor: "#fff", padding: "10px 10px" }}
      />
      <Button onClick={() => searchHandler(searchValue)} variant='contained' sx={{ padding: "12px 30px" }}>Search</Button>
    </Stack>
  )
}

export default Search