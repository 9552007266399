
// ** HOME PAGE BANNER LABEL
export const HEALING_BEYOND_PRESCRIPTION = 'Healing Beyond Prescription'


// ** BLOOD REQUEST  COMPONENT LABELS
export const BDAP_BLOOD_REQUEST_CRITERIA = 'BDAP BLOOD REQUEST CRITERIA'
export const BLOOD_REQUEST_CRITERIA_PARRA = "Thank you for connecting us with our blood donation portal. To ensure that we can provide life-saving Donors to those in need, please take a few moments to carefully fill out the following particulars. Your time is invaluable and appreciated."

// ** WE CAN DELIVER COMPONENT LABELS
export const TOGETHER_WE_CAN_DELIVER_HEALTH_AND_HOPE = 'TOGETHER WE CAN DELIVER HEALTH AND HOPE TO THE WORLD!'
export const BLOOD_DONORS_ACROSS_PAKISTAN_HAS_STARTED_FEMALES = "Together, we can bring health and hope to those in need. Through the combined efforts of donors and volunteers, we can provide life-saving treatments and make a positive difference for countless people in our surroundings. Your generosity and compassion are invaluable, and together, we can deliver health and hope to the world."
export const BLOOD_CASES_SOLVED = 'Blood cases solved'
export const FOOD_BAGS_DELIVERED = 'Food Bags delivered'
export const OTHER_CASES = 'Other cases'

// ** JOIN THE MOVEMENT COMPONENT LEBELS
export const JOIN_THE_MOVEMENT = 'JOIN THE MOVEMENT'
export const SIGN_UP_HERE_TO_RECEIVE_EMAIL = 'Sign up here to receive our quarterly newsletter via email.'



