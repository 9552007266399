import React from 'react'
import { Container, Stack, Card, Grid, Typography, CardMedia } from '@mui/material'
import CenterHeading from '../centerHeading'
import HeartAndline from '../heartAndline'
const AccountDetails = () => {
  const appStyle = {
    fontSize: ".9rem",
    fontWeight: 500
  }
  return (
    <Container>
      <Stack spacing={2} mt={8} justifyContent='center' alignItems='center'>
        <Stack style={{ width: "fit-content" }}>
          <HeartAndline {...{ showRightLine: true }} />
        </Stack>
        <CenterHeading {...{ heading: "For donation and account details" }} />
      </Stack>
      <Grid container mt={0} justifyContent='center' alignItems='flex-start' spacing={5}>
        <Grid item xs={12} sm={12} md={4}>
          <Stack mt={3}>
            <Card sx={{ padding: "1rem 2rem", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', borderTop: "solid 3px #89181C", cursor: 'pointer', borderRadius: "0px !important" }}>
              <Typography variant='h4' sx={{ fontSize: '1.5rem', textAlign: "center", fontWeight: 500, textTransform: "uppercase" }}>Donate with apps</Typography>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <CardMedia
                  component="img"
                  image="./assets/images/easypaisa.png"
                  alt="easypaisa img"
                  sx={{ width: '30px', height: '30px', objectFit: "fill" }}
                />
                <Typography sx={appStyle} variant='subtitle1'>EASY PAISA</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <CardMedia
                  component="img"
                  image="./assets/images/jazzcash.png"
                  alt="jazzcash img"
                  sx={{ width: '30px', height: '30px', objectFit: "fill" }}
                />
                <Typography sx={appStyle} variant='subtitle1'>JAZZ CASH</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <CardMedia
                  component="img"
                  image="./assets/images/sadapay.png"
                  alt="sadapay img"
                  sx={{ width: '30px', height: '30px', objectFit: "fill" }}
                />
                <Typography sx={appStyle} variant='subtitle1'>SADA PAY</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <CardMedia
                  component="img"
                  image="./assets/images/nayapay.png"
                  alt="nayapay img"
                  sx={{ width: '30px', height: '30px', objectFit: "fill" }}
                />
                <Typography sx={appStyle} variant='subtitle1'>NAYA PAY</Typography>
              </Stack>

              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <CardMedia
                  component="img"
                  image="./assets/images/konnect.png"
                  alt="konnect img"
                  sx={{ width: '30px', height: '30px', objectFit: "fill" }}
                />
                <Typography sx={appStyle} variant='subtitle1'>HBL KONNECT APP</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <Typography sx={{ fontSize: '1rem', fontWeight: 700 }} variant='subtitle1'>Account #</Typography>
                <Typography sx={appStyle} variant='body1'>+92-301-425-0240</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <Typography sx={{ fontSize: '1rem', fontWeight: 700 }} variant='subtitle1'>Account Name</Typography>
                <Typography sx={appStyle} variant='body1'>Muhammad Nabeel Akhtar</Typography>
              </Stack>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Stack mt={3}>
            <Card sx={{ padding: "1rem 2rem", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', borderTop: "solid 3px #89181C", cursor: 'pointer', borderRadius: "0px !important" }}>
              <Typography variant='h4' sx={{ fontSize: '1.5rem', textAlign: "center", fontWeight: 500, textTransform: "uppercase" }}>Donate with apps</Typography>
             <Typography variant='subtitle2' sx={{  textAlign: "center", fontWeight: 400, textTransform: "uppercase" }}>For USA & Canada</Typography>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <CardMedia
                  component="img"
                  image="./assets/images/zelle.png"
                  alt="easypaisa img"
                  sx={{ width: '30px', height: '30px', objectFit: "fill" }}
                />
                <Typography sx={appStyle} variant='subtitle1'>Zelle</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <CardMedia
                  component="img"
                  image="./assets/images/applePay.png"
                  alt="jazzcash img"
                  sx={{ width: '50px', height: '20px', objectFit: "fill" }}
                />
                <Typography sx={appStyle} variant='subtitle1'>Apple Pay</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <Typography sx={{ fontSize: '1rem', fontWeight: 700 }} variant='subtitle1'>Account #</Typography>
                <Typography sx={appStyle} variant='body1'>+1 (916) 270 8672</Typography>
              </Stack>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={4} >
          <Stack mt={3}>
            <Card sx={{ padding: "1rem 2rem 3rem 2rem", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', borderTop: "solid 3px #89181C", cursor: 'pointer', borderRadius: "0px !important" }}>
              <Typography variant='h4' sx={{ fontSize: '1.5rem', textAlign: "center", fontWeight: 500, textTransform: "uppercase" }}>Donate With bank</Typography>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <Typography sx={{ fontSize: '1rem', fontWeight: 700 }} variant='subtitle1'>Bank Name</Typography>
                <Typography sx={appStyle} variant='body1'>Faysal Bank</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <Typography sx={{ fontSize: '1rem', fontWeight: 700 }} variant='subtitle1'>A/C #</Typography>
                <Typography sx={appStyle} variant='body1'>3376383000000043</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <Typography sx={{ fontSize: '1rem', fontWeight: 700 }} variant='subtitle1'>IBAN #</Typography>
                <Typography sx={appStyle} variant='body1'>PK75FAYS3376383000000043</Typography>
              </Stack>
              <Stack spacing={1} mt={2} direction='row' justifyContent='flex-start' alignItems='center'>
                <Typography sx={{ fontSize: '1rem', fontWeight: 700 }} variant='subtitle1'>Payoneer Account ID</Typography>
                <Typography sx={appStyle} variant='body1'>24550494</Typography>
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AccountDetails