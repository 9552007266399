import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import "./slider.css";
import { HEALING_BEYOND_PRESCRIPTION } from '../../shared/constants/home'
// import required modules
import { Autoplay } from "swiper";
import { Box, Button, CardMedia, Stack, Typography } from "@mui/material";
import styled from "styled-components"
import { Link as RouterLink } from 'react-router-dom'

const SliderImg = styled(Box)({
  backgroundImage: "linear-gradient(0deg, rgba(137, 24, 28, 0.6), rgba(137, 24, 28, 0.6)), url(./assets/home/sliderBG.jpg)",
  height: "100%",
  width: "100%",
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  '@media(min-width:320px) and (max-width:900px)': {
    backgroundSize: 'cover',
  }
})
const BannerContainer = styled.div({
  marginTop: "0px",
  height: "70vh",
  '@media(min-width:320px) and (max-width:400px)': {
    height: "60vh"
  },
})
const BannerHeading = styled(Typography)({
  '@media(min-width:320px) and (max-width:400px)': {
    fontSize: "1.1rem",
    marginTop: "-10px"
  },
  '@media(min-width:401px) and (max-width:550px)': {
    fontSize: "1.3rem",
    marginTop: "-10px",
  },
  '@media(min-width:551px) and (max-width:700px)': {
    fontSize: "1.8rem",
    marginTop: "-10px"
  },
  '@media(min-width:701px) and (max-width:810px)': {
    fontSize: "2.5rem",
    marginTop: "-10px"
  },
  '@media(min-width:811px) and (max-width:900px)': {
    fontSize: "2.7rem",
    marginTop: "-10px"
  },
  '@media(min-width:901px) and (max-width:1010px)': {
    fontSize: "3rem",
    marginTop: "-10px"
  },
  '@media(min-width:1011px) and (max-width:1110px)': {
    fontSize: "3.2rem",
    marginTop: "-10px"
  },
  '@media(min-width:1111px) and (max-width:1200px)': {
    fontSize: "3.4rem",
    marginTop: "-10px"
  }
})
const DropImg = styled(CardMedia)({
  marginBottom: "10px",
  '@media(min-width:320px) and (max-width:900px)': {
    marginBottom: "20px",
    height: '10rem'
  },
  '@media(min-width:901px) and (max-width:1300px)': {
    marginBottom: "0px",
    height: '12rem'
  },
  // '@media(min-width:1201px) and (max-width:1327px)': {
  //   marginBottom: "0px",
  // },
})
const BannerButton = styled(Button)({
  textTransform: "uppercase",
  padding: '10px 20px',
  fontSize: "1.1rem",
  color: "#89181C",
  backgroundColor: "#FFFFFF",
  '&:hover ': {
    backgroundColor: "#FFFFFF !important"
  }
})
export default function Banner() {
  return (
    <BannerContainer>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={false}
        // autoplay={{
        //   delay: 2500,
        //   disableOnInteraction: true,
        // }}
        grabCursor={true}
        navigation={false}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <SliderImg>
            <Stack sx={{ height: "100%" }} justifyContent='center' alignItems='center' >
              <DropImg
                component="img"
                image="./assets/images/dropMap.svg"
                alt="nav image"
                sx={{ width: 'fit-content', height: '14rem', cursor: 'pointer' }}
              />
              <BannerHeading variant="h2" sx={{ textTransform: "uppercase", marginBottom: "6px", marginTop: "-10px", fontSize: '4.1rem', color: "#fff", fontWeight: '700 !important', }}>{HEALING_BEYOND_PRESCRIPTION}</BannerHeading>
              <BannerButton component={RouterLink} to='donate' variant="contained">Donate Now</BannerButton>
            </Stack>
          </SliderImg>
        </SwiperSlide>
        <SliderImg>
          <Stack sx={{ height: "100%" }} justifyContent='center' alignItems='center'>
            <CardMedia
              component="img"
              image="./assets/home/dropAndMap.svg"
              alt="nav image"
              sx={{ width: 'fit-content', height: '230px', cursor: 'pointer' }}
            />
            <Typography sx={{ marginTop: "-40px", fontSize: '4.1rem', color: "#fff", fontWeight: '700 !important' }}>{HEALING_BEYOND_PRESCRIPTION}</Typography>
            <Button variant="contained" sx={{ textTransform: "uppercase", padding: '10px 20px', fontSize: "1.1rem", color: "#89181C", backgroundColor: "#FFFFFF" }}>Donate Now</Button>
          </Stack>
        </SliderImg>
        <SliderImg>
          <Stack sx={{ height: "100%" }} justifyContent='center' alignItems='center'>
            <CardMedia
              component="img"
              image="./assets/home/dropAndMap.svg"
              alt="nav image"
              sx={{ width: 'fit-content', height: '230px', cursor: 'pointer' }}
            />
            <Typography sx={{ marginTop: "-40px", fontSize: '4.1rem', color: "#fff", fontWeight: '700 !important' }}>{HEALING_BEYOND_PRESCRIPTION}</Typography>
            <Button variant="contained" sx={{ textTransform: "uppercase", padding: '10px 20px', fontSize: "1.1rem", color: "#89181C", backgroundColor: "#FFFFFF" }}>Donate Now</Button>
          </Stack>
        </SliderImg>
        <SliderImg>
          <Stack sx={{ height: "100%" }} justifyContent='center' alignItems='center'>
            <CardMedia
              component="img"
              image="./assets/home/dropAndMap.svg"
              alt="nav image"
              sx={{ width: 'fit-content', height: '230px', cursor: 'pointer' }}
            />
            <Typography sx={{ marginTop: "-40px", fontSize: '4.1rem', color: "#fff", fontWeight: '700 !important' }}>{HEALING_BEYOND_PRESCRIPTION}</Typography>
            <Button variant="contained" sx={{ textTransform: "uppercase", padding: '10px 20px', fontSize: "1.1rem", color: "#89181C", backgroundColor: "#FFFFFF" }}>Donate Now</Button>
          </Stack>
        </SliderImg>
      </Swiper>
    </BannerContainer>
  );
}
