import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Container, Grid } from '@mui/material'
import AddDonorForm from '../components/admin/donors/addDonor'
import { JOIN_US } from '../shared/constants/APIs'
import Loading from '../components/loading'

// ***********************************************
const AddDonor = () => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false)
  const [message, setMessage] = useState('')
  const saveDonorHandler = async (values) => {
    setShowSpinner(true)
    const blood = values.bloodGroup.trim().replace('ve', "")
    const params = {
      fullName: values.fullName.trim(),
      currentCity: values.city.trim(),
      contactNumber: values.contact.trim(),
      bloodGroup: blood,
      email: '',
      fatherName: '',
      dateOfBirth: '',
      homeAddress: '',
      cnic: '',
      joinAsA: ["Blood Donor"]
    }
    await axios.post(JOIN_US, params)
      .then((response) => {
        setMessage(response.data.message)
        setShowSpinner(false)

      }, (error) => {
        if (error) {
          navigate('/login')
        }
        setShowSpinner(false)
        console.log(error)
      })
  }
  return (
    <Container sx={{ marginTop: "50px" }}>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={5}>
          <AddDonorForm {...{ saveDonorHandler, message }} />
          <Loading {...{ showSpinner }} />
        </Grid>
      </Grid>

    </Container>
  )
}

export default AddDonor