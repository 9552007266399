import React from 'react'
import { CardMedia, Container, Grid, Stack, Typography } from '@mui/material'
import { saveLives } from '../shared/weSaveLives'
import CenterHeading from './centerHeading'
import styled from 'styled-components'

const GridItem = styled(Stack)({
  '@media(min-width:901px) and (max-width:1060px)': {
    padding: "40px 0px !important",
  }
})

const WeSaveLives = () => {
  return (
    <Container maxWidth='lg' sx={{ marginTop: "100px" }}>
      <CenterHeading {...{ heading: "We Heal Lives" }} />
      <Grid container>
        {saveLives.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={4}>
            <GridItem justifyContent='center' alignItems='center' spacing={2} p={{ xs: 2, sm: 5, md: 5 }} mt={{ xs: 0, sm: 0, md: 3 }}>
              <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <CardMedia
                  component="img"
                  image={item.img}
                  alt="nav image"
                  sx={{ width: 'fit-content', height: '100px', }}
                />
                <Typography sx={{ color: "#89181C", fontSize: "1.2rem", fontWeight: 600 }}>{item.title}</Typography>
              </Stack>
              <Typography sx={{
                ...(index === 0 && { marginLeft: "-19px !important" }),
                ...(index === 1 && { marginLeft: "-25px !important" }),
                ...(index === 3 && { marginLeft: "-40px !important" }),
                fontSize: '20px', fontWeight: 300, textAlign: "left", width: "260px", marginLeft: '25px'
              }}>{item.content}</Typography>
            </GridItem>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default WeSaveLives