import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'
import BloodRequestCard from '../components/requestBlood/bloodRequest'
import Page from '../components/helmet'
import Banner from '../components/banner'
import JoinMovement from '../components/joinMovement'
import axios from 'axios'
import { GET_BLOOD_REQUESTS } from '../shared/constants/APIs'
import Loading from '../components/loading'


const BloodRequest = () => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [hideLoadButton, setHideLoadButton] = useState(false)
  const [bloodRequests, setbloodRequests] = useState([])
  useEffect(() => {
    const getAllBloodRequests = async () => {
      setShowSpinner(true)
      await axios.post(GET_BLOOD_REQUESTS)
        .then((response) => {
          setbloodRequests([...response.data.data])
          setShowSpinner(false)
        }, (error) => {
          setShowSpinner(false)
          console.log(error)
        })
    }
    getAllBloodRequests()
  }, [])

  const LoadMoreHandler = async () => {
    setShowSpinner(true)
    const lastItem = bloodRequests.slice(-1)
    const params = {
      startAt: lastItem[0].id,
      search: searchValue === '' ? undefined : searchValue
    }
    await axios.post(GET_BLOOD_REQUESTS, params)
      .then((response) => {
        setShowSpinner(false)
        if (response.data.data.length === 0) {
          setHideLoadButton(true)
        } else {
          setbloodRequests([...bloodRequests, ...response.data.data])
        }
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  const searchHandler = async (value) => {
    setShowSpinner(true)
    setSearchValue(value)
    const params = {
      search: value === '' ? undefined : value
    }
    await axios.post(GET_BLOOD_REQUESTS, params)
      .then((response) => {
        setShowSpinner(false)
        console.log(response)
        setbloodRequests([...response.data.data])
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  return (
    <Page title='BDAP | blood Request'>
      <Banner {...{ BannerTitle: "Blood Requests" }} />
      <Container maxWidth='lg' sx={{ marginTop: "30px" }}>
        <BloodRequestCard {...{ bloodRequests, searchHandler, LoadMoreHandler, hideLoadButton }} />
      </Container>
      <Loading {...{ showSpinner }} />
      <JoinMovement />
    </Page>
  )
}

export default BloodRequest