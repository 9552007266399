import React, { useState, useEffect } from 'react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import axios from 'axios'
import { Container, Stack, Typography } from '@mui/material'
import DonorsTabel from '../components/admin/donors/donorsTabel'
import Loading from '../components/loading'
import { GET_ADMIN_DONORS, DELETE_BLOOD_DONOR, UPDATE_ADMIN_DONOR } from '../shared/constants/APIs'

// ******************************************

axios.defaults.withCredentials = true;
const AdminDonors = () => {
  const navigate = useNavigate();
  const [donors, setDonors] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [showFormDialog, setShowFormDialog] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(0)
  useEffect(() => {
    const getAllDonors = async () => {
      setShowSpinner(true)
      await axios.post(GET_ADMIN_DONORS)
        .then((response) => {
          setTotalCount(response.data.total)
          setDonors([...response.data.data])
          setShowSpinner(false)
        }, (error) => {
          if (error) {
            navigate('/login')
          }
          console.log(error)
          setShowSpinner(false)
        })
    }
    getAllDonors()
  }, [navigate])
  const LoadMoreHandler = async () => {
    setShowSpinner(true)
    const lastItem = donors.slice(-1)
    const params = {
      startAt: lastItem[0].id,
      search: searchValue === '' ? undefined : searchValue
    }
    console.log("🚀 ~ file: donors.js:26 ~ LoadMoreHandler ~ lastItemId", lastItem)
    await axios.post(GET_ADMIN_DONORS, params)
      .then((response) => {
        if (response.data.data.length > 0) {
          setDonors([...donors, ...response.data.data])
          setTotalCount(response.data.total)
        }
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }

  const searchHandler = async (value) => {
    setShowSpinner(true)
    setSearchValue(value)
    const params = {
      search: value === '' ? undefined : value
    }
    await axios.post(GET_ADMIN_DONORS, params)
      .then((response) => {
        setDonors([...response.data.data])
        setTotalCount(response.data.total)
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }

  const refreshDonors = async () => {
    setShowSpinner(true)
    console.log('refresh function call')
    await axios.post(GET_ADMIN_DONORS)
      .then((response) => {
        setDonors([...response.data.data])
        setTotalCount(response.data.total)
        setShowSpinner(false)
      }, (error) => {
        if (error) {
          navigate('/login')
        }
        console.log(error)
        setShowSpinner(false)
      })
    setPage(0)
  }

  const deleteHandler = async (item) => {
    setShowSpinner(true)
    const { id } = item
    const params = { id }
    await axios.delete(DELETE_BLOOD_DONOR, { data: params })
      .then((response) => {
        if (response) { }
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
    setDonors([])
    refreshDonors()
    setShowFormDialog(false)
  }

  const editDonorHandler = async (values, id) => {
    const params = {
      ...values,
      id
    }
    await axios.post(UPDATE_ADMIN_DONOR, params)
      .then((response) => {
        setShowSpinner(false)
        if (response) { }
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
    setDonors([])
    refreshDonors()
    setShowFormDialog(false)
  }

  return (
    <Container maxWidth='lg' sx={{ marginTop: "40px" }}>
      <Stack justifyContent='center' alignItems='center' mb={5}>
        <Typography variant='h6' sx={{ fontSize: "2rem" }}>Donors | Join Us Request</Typography>
      </Stack>
      <Stack justifyContent='flex-end' alignItems='flex-end' mb={5}>
        <Typography component={RouterLink} to='/s/admin-add-donor' variant='h6' sx={{ color: "#89181C", fontSize: "1.2rem", textDecoration: 'none', }}>+ Quick add donor</Typography>
      </Stack>
      <DonorsTabel {...{ setPage, page, totalCount, donors, searchHandler, LoadMoreHandler, deleteHandler, editDonorHandler, setShowFormDialog, showFormDialog }} />
      <Loading {...{ showSpinner }} />
    </Container>
  )
}

export default AdminDonors