import React from 'react'
import {
  TextField, Stack, Typography, Button, Card, FormControlLabel, Checkbox
} from '@mui/material'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'

export default function RequestBloodForm({ donorData, editDonorHandler, setShowFormDialog, showFormDialog }) {
  const bloodDonor = donorData && donorData.joinAsA.includes('Blood Donor')
  const projectsCoordinator = donorData && donorData.joinAsA.includes('Project Coordinator')
  const moderator = donorData && donorData.joinAsA.includes('Moderator')
  const volunteer = donorData && donorData.joinAsA.includes('Volunteer')

  const editDonorSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required'),
    fullName: Yup.string()
      .required('Full  name is required'),
    fatherName: Yup.string()
      .required('Full  name is required'),
    bloodGroup: Yup.string()
      .required('Blood group is required'),
    contact: Yup.string()
      .required('Contact number is required'),
    city: Yup.string()
      .required('City name is required'),
    status: Yup.string()
      .required('Status is required'),
  })
  const formik = useFormik({
    initialValues: {
      email: donorData && donorData.email,
      bloodGroup: donorData && donorData.bloodGroup,
      city: donorData && donorData.currentCity,
      fullName: donorData && donorData.fullName,
      contact: donorData && donorData.contactNumber,
      status: donorData && donorData.status,
      donateOn: donorData && donorData.donateOn,
      fatherName: donorData && donorData.fatherName,
      dateOfBirth: donorData && donorData.dateOfBirth,
      homeAddress: donorData && donorData.homeAddress,
      cnic: donorData && donorData.cnic,
      joinUsAsAVolunteer: volunteer,
      officialModerator: moderator,
      projectsCoordinators: projectsCoordinator,
      joinUsAsABloodDonor: bloodDonor

    },
    validationSchema: editDonorSchema,
    onSubmit: () => {
      editDonorHandler(values, donorData.id)
    }
  })

  const {
    errors, touched, handleSubmit, getFieldProps, values
  } = formik
  const fieldTextStyle = {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: "3px"
  }
  return (

    <Card sx={{ padding: '2rem 2rem 3rem 2rem', borderRadius: '5px' }}>
      <Stack mb={1}>
        <Typography variant='h4' sx={{ fontSize: "1.8rem", fontWeight: 600, textAlign: "center" }}>Edit Donor</Typography>
      </Stack>
      <FormikProvider value={formik}>
        <Form
          id='form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack spacing={2} sx={{ width: "100%" }} direction={{ xs: "column", sm: "column", md: 'row' }} justifyContent='space-between' alignItems='center'>
            <Stack sx={{ width: "100%" }} >
              <Typography variant='body1' sx={fieldTextStyle}>Contact Person Name</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='fullName'
                {...getFieldProps('fullName')}
                error={Boolean(touched.fullName && errors.fullName)}
                helperText={touched.fullName && errors.fullName}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Father Name</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='fatherName'
                {...getFieldProps('fatherName')}
                error={Boolean(touched.fatherName && errors.fatherName)}
                helperText={touched.fatherName && errors.fatherName}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Email</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='email'
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Contact</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='contact'
                {...getFieldProps('contact')}
                error={Boolean(touched.contact && errors.contact)}
                helperText={touched.contact && errors.contact}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Date of birth</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='dateOfBirth'
                {...getFieldProps('dateOfBirth')}
                error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                helperText={touched.dateOfBirth && errors.dateOfBirth}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Home address</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='homeAddress'
                {...getFieldProps('homeAddress')}
                error={Boolean(touched.homeAddress && errors.homeAddress)}
                helperText={touched.homeAddress && errors.homeAddress}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>CNIC</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='cnic'
                {...getFieldProps('cnic')}
                error={Boolean(touched.cnic && errors.cnic)}
                helperText={touched.cnic && errors.cnic}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Blood group</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='bloodGroup'
                {...getFieldProps('bloodGroup')}
                error={Boolean(touched.bloodGroup && errors.bloodGroup)}
                helperText={touched.bloodGroup && errors.bloodGroup}
                sx={{ marginBottom: '10px ' }}
              />

            </Stack>

          </Stack>
          <Stack sx={{ width: "100%" }} >
            <Typography variant='body1' sx={fieldTextStyle}>City</Typography>
            <TextField
              fullWidth
              size='medium'
              type='text'
              name='city'
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
              sx={{ marginBottom: '10px ' }}
            />
          </Stack>
          <Typography variant='body1' sx={fieldTextStyle}>Status</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='status'
            {...getFieldProps('status')}
            error={Boolean(touched.status && errors.status)}
            helperText={touched.status && errors.status}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Last donate on</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='donateOn'
            {...getFieldProps('donateOn')}
            error={Boolean(touched.donateOn && errors.donateOn)}
            helperText={touched.donateOn && errors.donateOn}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 700, textTransform: "capitalize" }}>	Join As A  </Typography>
          <Stack mt={1}>
            <Stack spacing={3.2} direction='row' justifyContent='flex-start' alignItems='center'>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('joinUsAsABloodDonor')} checked={values.joinUsAsABloodDonor} />}
                label="Blood Donor"
              />
              <FormControlLabel
                control={<Checkbox {...getFieldProps('joinUsAsAVolunteer')} checked={values.joinUsAsAVolunteer} />}
                label="Volunteer"
              />
            </Stack>
            <Stack direction='row' justifyContent='flex-start' alignItems='center'>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('officialModerator')} checked={values.officialModerator} />}
                label="Official Moderator"
              />
              <FormControlLabel
                control={<Checkbox {...getFieldProps('projectsCoordinators')} checked={values.projectsCoordinators} />}
                label="Projects Coordinator"
              />
            </Stack>
          </Stack>
          <Stack mt={4} direction='row' spacing={1}>
            <Button type='submit' fullWidth color='secondary' variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Save</Button>
            <Button onClick={() => setShowFormDialog(!showFormDialog)} type='submit' fullWidth variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Cancel</Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  )
}
