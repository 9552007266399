import React from 'react'
import { TextField, Stack, Typography, Button, Card, Box } from '@mui/material'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'

export default function ProjectForm({ editProjectHandler, saveProjectHandler, setShowFormDialog, project = { title: '', imageUrl: '', description: '', status: '', startDate: '', endDate: '' } }) {
  const { title, imageUrl, description, status, startDate, endDate } = project
  const projectSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title url is required'),
    imageUrl: Yup.string()
      .required('Img url is required'),
    description: Yup.string()
      .required('Description is required'),
    status: Yup.string()
      .required('Status is required'),
    startDate: Yup.string()
      .required('Start date is required')
  })
  const formik = useFormik({
    initialValues: {
      title,
      imageUrl,
      description,
      status,
      startDate,
      endDate
    },
    validationSchema: projectSchema,
    onSubmit: () => {
      if (project?.id) {
        editProjectHandler(values, project.id)
      } else {
        saveProjectHandler(values)
      }
      resetForm()
    }
  })

  const {
    errors, touched, handleSubmit, getFieldProps, values, resetForm
  } = formik
  const fieldTextStyle = {
    fontSize: '16px',
    fontWeight: 500
  }
  return (
    <Card sx={{ padding: project ? '1rem 2rem' : '3rem', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', }}>
      <Stack mb={3}>
        <Typography variant='h4' sx={{ fontSize: "1.8rem", fontWeight: 700, textAlign: "center" }}>{project ? 'Edit Project' : 'Add Project'}</Typography>
      </Stack>
      <FormikProvider value={formik}>
        <Form
          id='form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Typography variant='body1' sx={fieldTextStyle}>Title</Typography>
          <TextField
            fullWidth
            size='small'
            type='text'
            name='title'
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            sx={{ marginBottom: '10px', }}
          />
          <Stack spacing={2} direction='row' justifyContent='space-between' alignItems='center'>
            <Box sx={{ width: "100%" }}>
              <Typography variant='body1' sx={fieldTextStyle}>Gdrive Image ID</Typography>
              <TextField
                fullWidth
                size='small'
                type='text'
                name='imageUrl'
                {...getFieldProps('imageUrl')}
                error={Boolean(touched.imageUrl && errors.imageUrl)}
                helperText={touched.imageUrl && errors.imageUrl}
                sx={{ marginBottom: '10px ' }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography variant='body1' sx={fieldTextStyle}>Status</Typography>
              <TextField
                fullWidth
                size='small'
                type='text'
                name='status'
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
                sx={{ marginBottom: '10px ' }}
              />
            </Box>
          </Stack>
          <Stack spacing={2} direction='row' justifyContent='space-between' alignItems='center'>
            <Box sx={{ width: "100%" }}>
              <Typography variant='body1' sx={fieldTextStyle}>Start date</Typography>
              <TextField
                fullWidth
                size='small'
                type='date'
                name='startDate'
                {...getFieldProps('startDate')}
                error={Boolean(touched.startDate && errors.startDate)}
                helperText={touched.startDate && errors.startDate}
                sx={{ marginBottom: '10px ' }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography variant='body1' sx={fieldTextStyle}>End date</Typography>
              <TextField
                fullWidth
                size='small'
                type='date'
                name='endDate'
                {...getFieldProps('endDate')}
                error={Boolean(touched.endDate && errors.endDate)}
                helperText={touched.endDate && errors.endDate}
                sx={{ marginBottom: '10px ' }}
              />
            </Box>
          </Stack>
          <Typography variant='body1' sx={fieldTextStyle}>Description</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            multiline
            minRows={4}
            name='description'
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            sx={{ marginBottom: '10px ' }}
          />

          <Stack mt={4}>
            <Button type='submit' fullWidth variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Save</Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>

  )
}
