import React from 'react'
import {
  TextField, Stack, Typography, Button, Card, FormControlLabel, Checkbox
} from '@mui/material'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
// import Zoom from '@mui/material/Zoom'


// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Zoom timeout={1000} ref={ref} {...props} />;
// })


export default function JoinUsForm({ saveJoinUsHandler }) {
  const joinUsSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    fullName: Yup.string()
      .required('Full Name is required'),
    fatherName: Yup.string()
      .required('Father Name is required'),
    dateOfBirth: Yup.string()
      .required('Date of birth is required'),
    homeAddress: Yup.string()
      .required('Home address is required'),
    cnic: Yup.string()
      .required('CNIC is required'),
    currentCity: Yup.string()
      .required('city is required'),
    contactNumber: Yup.string()
      .required('Contact number is required'),
    bloodGroup: Yup.string()
      .required('Blood group is required'),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      fatherName: '',
      dateOfBirth: '',
      homeAddress: '',
      cnic: '',
      currentCity: '',
      contactNumber: '',
      bloodGroup: '',
      joinUsAsAVolunteer: false,
      officialModerator: false,
      projectsCoordinators: false,
      joinUsAsABloodDonor: true
    },
    validationSchema: joinUsSchema,
    onSubmit: () => {
      saveJoinUsHandler(values)
      resetForm()
    }
  })

  const {
    errors, touched, handleSubmit, getFieldProps, values, resetForm
  } = formik
  const fieldTextStyle = {
    fontSize: '16px',
    fontWeight: 500
  }
  return (
    <Card sx={{ padding: '2rem 3rem', borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', }}>
      <Stack mb={3}>
        <Typography variant='h4' sx={{ fontSize: "1.8rem", fontWeight: 700, textAlign: "center" }}>Join Us</Typography>
      </Stack>
      <FormikProvider value={formik}>
        <Form
          id='form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Typography variant='body1' sx={fieldTextStyle}>Email</Typography>
          <TextField
            fullWidth
            variant="outlined"
            size='medium'
            type='text'
            name='email'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            sx={{ marginBottom: '10px ' }}

          />
          <Typography variant='body1' sx={fieldTextStyle}>Full Name</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='fullName'
            {...getFieldProps('fullName')}
            error={Boolean(touched.fullName && errors.fullName)}
            helperText={touched.fullName && errors.fullName}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Father Name</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='fatherName'
            {...getFieldProps('fatherName')}
            error={Boolean(touched.fatherName && errors.fatherName)}
            helperText={touched.fatherName && errors.fatherName}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Date Of Birth</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='dateOfBirth'
            {...getFieldProps('dateOfBirth')}
            error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
            helperText={touched.dateOfBirth && errors.dateOfBirth}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Home Address</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='homeAddress'
            {...getFieldProps('homeAddress')}
            error={Boolean(touched.homeAddress && errors.homeAddress)}
            helperText={touched.homeAddress && errors.homeAddress}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>CNIC/ B Form</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='cnic'
            {...getFieldProps('cnic')}
            error={Boolean(touched.cnic && errors.cnic)}
            helperText={touched.cnic && errors.cnic}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Current City</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='currentCity'
            {...getFieldProps('currentCity')}
            error={Boolean(touched.currentCity && errors.currentCity)}
            helperText={touched.currentCity && errors.currentCity}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Contact Number</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='contactNumber'
            {...getFieldProps('contactNumber')}
            error={Boolean(touched.contactNumber && errors.contactNumber)}
            helperText={touched.contactNumber && errors.contactNumber}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Blood Group</Typography>
          <TextField
            fullWidth
            size='medium'
            type='text'
            name='bloodGroup'
            {...getFieldProps('bloodGroup')}
            error={Boolean(touched.bloodGroup && errors.bloodGroup)}
            helperText={touched.bloodGroup && errors.bloodGroup}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={{ fontSize: "1.2rem", fontWeight: 700, textTransform: "capitalize" }}>	Join As A  </Typography>
          <Stack mt={1}>
            <Stack spacing={3.2} direction='row' justifyContent='flex-start' alignItems='center'>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('joinUsAsABloodDonor')} checked={values.joinUsAsABloodDonor} />}
                label="Blood Donor"
              />
              <FormControlLabel
                control={<Checkbox {...getFieldProps('joinUsAsAVolunteer')} checked={values.joinUsAsAVolunteer} />}
                label="Volunteer"
              />
            </Stack>
            <Stack direction='row' justifyContent='flex-start' alignItems='center'>
              <FormControlLabel
                control={<Checkbox {...getFieldProps('officialModerator')} checked={values.officialModerator} />}
                label="Official Moderator"
              />
              <FormControlLabel
                control={<Checkbox {...getFieldProps('projectsCoordinators')} checked={values.projectsCoordinators} />}
                label="Projects Coordinator"
              />
            </Stack>
          </Stack>

          <Stack mt={4}>
            <Button type='submit' fullWidth variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Join us</Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>

  )
}
