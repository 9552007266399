import React from 'react'
import { Button, CardMedia, Container, Grid, Stack, Typography, Link } from '@mui/material'
import { Icon } from '@iconify/react'
import styled from 'styled-components'
import { NavLink, Link as RouterLink } from 'react-router-dom'
import {
  FOOTER_PARAGRAPH,
  FOOTER_EMAIL_LINK,
  ABOUT_US,
  OUR_PROJECTS,
  DONORS,
  BLOOD_REQUESTS,
  CONTACT,
  JOIN_US,
} from '../shared/constants/footer'
const Links = styled(NavLink)({
  textDecoration: "none",
  textTransform: 'upperCase',
  color: "#000",
  fontWeight: 700
})
const FooterCotnainer = styled(Container)({
  '@media(min-width:320px) and (max-width:900px)': {
    padding: '0px 30px 100px 30px !important'
  }
})
const BottomButtons = styled(Button)({
  '@media(min-width:320px) and (max-width:380px)': {
    padding: "10px !important",
    fontSize: ".7rem"
  }
})
const FooterTag = styled('footer')({
  width: "100%"
})
const Footer = () => {
  const ButtonStyles = {
    padding: "10px 20px"
  }
  const ExternalLinksStyle = {
    color: "#000"
  }
  return (
    <FooterTag>
      <FooterCotnainer maxWidth='lg' sx={{ marginTop: "100px", padding: "0px 80px 50px 80px !important" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={3}>
            <Stack justifyContent='center' alignItems='flex-start'>
              <Stack sx={{ backgroundColor: "#89181C" }}>
                <CardMedia
                  component="img"
                  image="/assets/whiteLogo.svg"
                  alt="nav image"
                  sx={{ width: '130px', height: '130px' }}
                />
              </Stack>
              <Stack mt={{ xs: 2, sm: 2, md: 3 }} direction='row' justifyContent='center' alignItems='center' spacing={2}>
                {/* https://www.facebook.com/BDAP.PK/ */}
                {/* https://twitter.com/bdap_ */}
                {/* https://www.instagram.com/bdap.pk */}
                <Link sx={ExternalLinksStyle} href='https://www.facebook.com/BDAP.PK/' target='chusama'><Icon icon="ri:facebook-fill" width='30px' height='30px' /></Link>
                <Link sx={ExternalLinksStyle} href='https://www.instagram.com/bdap.pk' target='chusama'><Icon icon="mdi:instagram" width='30px' height='30px' /></Link>
                <Link sx={ExternalLinksStyle} href='https://twitter.com/bdap_' target='chusama'><Icon icon="mdi:twitter" width='30px' height='30px' /></Link>
                {/* <Link sx={ExternalLinksStyle} href='#'><Icon icon="ri:linkedin-fill" width='30px' height='30px' /></Link> */}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Stack ml={{ xs: 0, sm: 0, md: 4 }} mt={{ xs: 3, sm: 3, md: 0 }} spacing={{ xs: 1, sm: 1, md: 1.7 }} justifyContent='flex-start' alignItems='flex-start' sx={{ height: "100%" }}>
              <Links to='blood-donors'>{DONORS}</Links>
              <Links to='blood-requests'>{BLOOD_REQUESTS}</Links>
              <Links to='our-projects'>{OUR_PROJECTS}</Links>
              <Links to='about-us'>{ABOUT_US}</Links>
              <Links to='contact-us'>{CONTACT}</Links>
              <Links to='join-us'>{JOIN_US}</Links>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={2} mt={{ xs: 3, sm: 3, md: 0 }}>
              <Typography variant='body1' sx={{ color: "#000", fontSize: "1.1rem", fontWeight: 400 }}>
                {FOOTER_PARAGRAPH}
              </Typography>
              <Typography variant='subtitle1' sx={{ fontWeight: 700, color: "#89181C" }}>{FOOTER_EMAIL_LINK}</Typography>
              <Stack sx={{ marginTop: "40px !important" }} spacing={1} direction='row' justifyContent='flex-start' alignItems='flex-start'>
                <BottomButtons component={RouterLink} to='blood-request' sx={ButtonStyles} variant='contained'>request for blood</BottomButtons>
                <BottomButtons component={RouterLink} to='donate' sx={ButtonStyles} variant='contained' color='secondary'>Donate</BottomButtons>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </FooterCotnainer>
      <Container maxWidth='100%' sx={{ backgroundColor: "#e5e5e5", padding: "5px 0px" }}>
        <Container maxWidth='lg' >
          <Stack spacing={2} sx={{ padding: "10px 40px" }} direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' alignItems='center'>
            <Typography variant='subtitle1' sx={{ color: "#000", fontSize: "0.8rem" }}>© 2023 <span style={{ fontWeight: 700 }}>BDAP.PK</span> All Rights Reserved</Typography>
            <Typography variant='subtitle1' sx={{ color: "#000", fontSize: "0.8rem" }}>Developed by <Link sx={{ textDecoration: "none", cursor: 'pointer', fontWeight: 600 }} href='https://www.casoft.us' target='chusmaa' >CA Soft</Link></Typography>
          </Stack>
        </Container>
      </Container>
    </FooterTag>
  )
}

export default Footer