import { Container } from '@mui/material'
import React from 'react'
import Banner from '../components/banner'
import Page from '../components/helmet'
import JoinMovement from '../components/joinMovement'
import RequestForm from '../components/requestBlood/requestBloodForm'
const BloodRequestForm = () => {
  return (
    <Page title='BDAP | Blood Request'>
      <Banner {...{ BannerTitle: "Blood Request" }} />
      <Container maxWidth='md' style={{ marginTop: "60px" }}>
        <RequestForm />
      </Container>
      <JoinMovement />
    </Page>
  )
}

export default BloodRequestForm