import { Stack, Typography } from '@mui/material'
import React from 'react'

const About = () => {
  const headingStyle = {
    fontSize: "1.5rem",
    fontWeight: 600
  }
  const contentStyle = {
    fontSize: "1rem",
    fontWeight: 300,
    textAlign: 'justify',
  }
  return (
    <>
      <Stack spacing={2}>
        <Typography variant='h6' sx={headingStyle}>We're glad to have you on board!</Typography>
        <Stack spacing={3}>
          <Typography variant='body1' sx={contentStyle}>Blood Donors across Pakistan (<span style={{ fontWeight: 700 }}>BDAP</span>) is the pioneer in voluntary healthy blood donation services all over Pakistan. <span style={{ fontWeight: 700 }}>BDAP</span> was set up in June 2017 by Muhammad Nabeel Akhtar with the aim of supporting the people through blood donations and providing help in every possible way.</Typography>
          <Typography variant='body1' sx={contentStyle}>Many private and public centres are performing their duties in this stance with their own limitations. Private sectors charged a high fee for blood and its transfusion services. Public centres have major difficulties regarding the availability of healthy and fresh blood.</Typography>
          <Typography variant='body1' sx={contentStyle}><span style={{ fontWeight: 700 }}>BDAP</span> from Lahore had started to provide healthy and on-the-spot blood donations. Now promoting the shortage of healthy blood for every person across Pakistan. It started working on these important issues and found blood donors as well as philanthropists. So, this work can be carried out on a bigger level.</Typography>
          <Typography variant='body1' sx={contentStyle}>Today, <span style={{ fontWeight: 700 }}>BDAP</span> perhaps is the biggest social organisation all over Pakistan that is providing blood through volunteers/blood donors. A team of competent administration and moderators are available for 24 hours to follow up on the cases and to meet up the utility of a receiver.</Typography>
        </Stack>
      </Stack>
      <Stack spacing={2} mt={2}>
        <Typography variant='h6' sx={headingStyle}>Our Story</Typography>
        <Stack spacing={3}>
          <Typography variant='body1' sx={contentStyle}>Just like any other day in June 2017, he (Wajahat Abbasi) was going back home with his friends (Towards Murree) from his university (In Rawalpindi). He didn't know he was about to be blindsided by another car entering the intersection going way too fast. His car fell through mountains and he lost his 3 friends on the spot.</Typography>
          <Typography variant='body1' sx={contentStyle}>His car accident left him bedridden for months, and it also put his family in a difficult situation at that time. At that time it was a challenging situation for me to arrange blood for him by sitting in Lahore, but after some struggle, I was able to arrange donors, then after his recovery, I got an idea that if I can arrange blood in Rawalpindi by sitting in Lahore, then yes I can arrange in any city of Pakistan.</Typography>
          <Typography variant='body1' sx={contentStyle}>Then I decided to make a group named Blood Donors across Pakistan (BDAP) after that incident and now everything is in front of you. He is still learning to cope mentally, the trauma his spine suffered during the accident left him immobile for almost 2 months. He was unable to walk without any support even for the next 1 year. There are times when his family is reminded of the pain brought about by that day.</Typography>
          <Typography variant='body1' sx={contentStyle}>Those who have experienced an accident can feel the shock, physical discomfort, and posttraumatic stress long after the actual event. Depression can take a stronghold, sometimes precluding recovery to a normal life after the accident.</Typography>
          <Typography variant='body1' sx={contentStyle}>And it's not just the people in the accident who suffer and need to recover, family members and loved ones can also see their lives change from day to night.</Typography>
        </Stack>
      </Stack>
      <Stack spacing={2} mt={2}>
        <Typography variant='h6' sx={headingStyle}>Our Mission</Typography>
        <Stack>
          <Typography variant='body1' sx={contentStyle}>Blood Donors across Pakistan is to serve humanity and to make a bridge between the needy patients and hospitals to save someone’s life. BDAP organisation is to improve the lives of Pakistanis by providing awareness to the general public about blood donations. Every Pakistani must know his/her blood group so that in case of emergency the life of a person can be saved.</Typography>
        </Stack>
      </Stack>
      <Stack spacing={2} mt={2}>
        <Typography variant='h6' sx={headingStyle}>Our Aims and Objectives</Typography>
        <Stack>
          <Typography variant='body1' sx={contentStyle}>Our main object and aims are to spread awareness about blood donation and blood-related diseases like thalassemia, Hepatitis C & B, which affect the people during a blood transfusion. We want to educate people about their health and facilitate them, and want to educate them that donating blood is not harmful or dangerous to life. Many of the people in our society do not feel good about donating blood to save their life and our society has many misconceptions, like weakness and many others like this. We just want to educate them about the positive impacts of blood donation and charity that can bring reforms in others life</Typography>
        </Stack>
      </Stack>
    </>
  )
}

export default About