import React, { useState } from 'react'
import { Container, Grid } from '@mui/material'
import LoginForm from '../components/login/loginForm'
import axios from 'axios'
import { USER_LOGIN } from '../shared/constants/APIs'
import Loading from '../components/loading'
import { useNavigate } from 'react-router-dom'
// ***********************************************
axios.defaults.withCredentials = true;
const Login = () => {
  const navigate = useNavigate()
  const [showSpinner, setShowSpinner] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    message: '',
    success: false
  })

  const onLoginSubmit = async (values) => {
    setShowSpinner(true)
    const { email, password } = values
    const params = {
      username: email,
      password
    }
    // try {
    await axios.post(USER_LOGIN, params)
      .then((response) => {
        setShowSpinner(false)
        setErrorMessage({
          message: response.data.message,
          success: true
        })
        navigate('/s/admin-blood-donors')
      }, (error) => {
        setShowSpinner(false)
        setErrorMessage({
          message: error.response.data.message,
          success: false
        })
        console.error(error)
      })
  }
  return (
    <Container sx={{ marginTop: "150px" }}>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={5}>
          <LoginForm {...{ onLoginSubmit, errorMessage }} />
          <Loading {...{ showSpinner }} />
        </Grid>
      </Grid>

    </Container>
  )
}

export default Login