const BASE_URL = 'https://bdap.pk/api/'

export const USER_LOGIN = `${BASE_URL}login`
export const USER_LOGOUT = `${BASE_URL}logout`
export const BLOOD_REQUIEST = `${BASE_URL}blood-request`
export const JOIN_US = `${BASE_URL}signup`
export const SAVE_PROJECT = `${BASE_URL}project`
export const SUBSCRIBE = `${BASE_URL}b/subscribe`
export const GET_PROJECTS = `${BASE_URL}projects`
export const GET_DONORS = `${BASE_URL}donors`
export const GET_BLOOD_REQUESTS = `${BASE_URL}blood-requests`
export const DELETE_BLOOD_REQUEST = `${BASE_URL}bdap-admin/blood-request`
export const DELETE_BLOOD_DONOR = `${BASE_URL}bdap-admin/donor`
export const UPDATE_PROJECT = `${BASE_URL}bdap-admin/update-project`
export const DELETE_PROJECT = `${BASE_URL}bdap-admin/project`

export const GET_ADMIN_DONORS = `${BASE_URL}bdap-admin/donors`
export const GET_ADMIN_BLOOD_REQUESTS = `${BASE_URL}bdap-admin/blood-requests`
export const UPDATE_ADMIN_DONOR = `${BASE_URL}bdap-admin/update-donor`
export const UPDATE_ADMIN_BLOOD_REQUEST = `${BASE_URL}bdap-admin/update-blood-request`


export const GET_ADMIN_USERS = `${BASE_URL}bdap-admin/users`
export const DELETE_ADMIN_USERS = `${BASE_URL}bdap-admin/user`
export const SAVE_ADMIN_USERS = `${BASE_URL}bdap-admin/user`



