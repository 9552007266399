import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material'
import DonorsCard from '../components/donors/donors'
import Page from '../components/helmet'
import Banner from '../components/banner'
import JoinMovement from '../components/joinMovement'
import { GET_DONORS } from '../shared/constants/APIs'
import axios from 'axios'
import Loading from '../components/loading'
// ********************************************
const Donors = () => {
  const [donors, setDonors] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [hideLoadButton, setHideLoadButton] = useState(false)
  useEffect(() => {
    const getAllDonors = async () => {
      setShowSpinner(true)
      await axios.post(GET_DONORS)
        .then((response) => {
          setDonors([...response.data.data])
          setShowSpinner(false)
        }, (error) => {
          console.log(error)
          setShowSpinner(false)
        })
    }
    getAllDonors()
  }, [])
  const LoadMoreHandler = async () => {
    setShowSpinner(true)
    const lastItem = donors.slice(-1)
    const params = {
      startAt: lastItem[0].id,
      search: searchValue === '' ? undefined : searchValue
    }
    await axios.post(GET_DONORS, params)
      .then((response) => {
        if (response.data.data.length === 0) {
          setHideLoadButton(true)
        } else {
          setDonors([...donors, ...response.data.data])
        }
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  const searchHandler = async (value) => {
    setShowSpinner(true)
    setSearchValue(value)
    const params = {
      search: value === '' ? undefined : value
    }
    await axios.post(GET_DONORS, params)
      .then((response) => {
        setDonors([...response.data.data])
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  return (
    <Page title='BDAP | Donors'>
      <Banner {...{ BannerTitle: "donate a drop and make a change" }} />
      <Container maxWidth='lg' sx={{ marginTop: "30px" }}>
        <DonorsCard {...{ donors, LoadMoreHandler, searchHandler, hideLoadButton }} />
        <Loading {...{ showSpinner }} />
      </Container>
      <JoinMovement />
    </Page>
  )
}

export default Donors