import React from 'react'
import { Link as RouterLink, Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Stack, CardMedia } from '@mui/material'
import BDAPLogo from './BDAP-Logo.webp'
// ----------------------------------------------------------------------

const HeaderStyle = styled('header')({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute'
})

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      <HeaderStyle>
        <Stack sx={{ width: 'fit-content !important' }}>
          <RouterLink to='/'>
            <CardMedia
              component="img"
              image={BDAPLogo}
              alt="nav image"
              sx={{ width: 'fit-content', height: '60px', cursor: 'pointer', objectFit: 'fill', marginLeft: '24px', marginTop:"8px"}}
            />
          </RouterLink>
        </Stack>
      </HeaderStyle>
      <Outlet />
    </>
  )
}
