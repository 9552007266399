import React from 'react'
import Page from '../components/helmet'
import Banner from '../components/banner'
import JoinMovement from '../components/joinMovement'
import AccountDetails from '../components/donate/accountDetails'
const Donors = () => {
  return (
    <Page title='BDAP | Donate'>
      <Banner {...{ BannerTitle: "Donate" }} />
      <AccountDetails />
      <JoinMovement />
    </Page>
  )
}

export default Donors