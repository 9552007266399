import React from 'react'
import {
  TextField, Stack, Typography, Button, Card
} from '@mui/material'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'

export default function LoginForm({ onLoginSubmit, errorMessage }) {
  const { message, success } = errorMessage
  const messageColor = success ? 'green' : '#89181C'
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      onLoginSubmit(values)
      if(success) {
        resetForm()
      }
    }
  })

  const {
    errors, touched, handleSubmit, getFieldProps, values, resetForm
  } = formik
  const fieldTextStyle = {
    fontSize: '16px',
    fontWeight: 500
  }
  return (

    <Card sx={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', padding: '3rem', borderRadius: '15px' }}>
      <Stack mb={3} justifyContent='center' alignItems='center'>
        <Typography variant='h4' sx={{ fontSize: "1.8rem", fontWeight: 700 }}>Member Login</Typography>
      </Stack>
      <FormikProvider value={formik}>
        <Form
          id='form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Typography variant='body1' sx={fieldTextStyle}>Email</Typography>
          <TextField
            fullWidth
            variant="outlined"
            size='medium'
            type='text'
            name='email'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            sx={{ marginBottom: '10px ' }}
          />
          <Typography variant='body1' sx={fieldTextStyle}>Password</Typography>
          <TextField
            fullWidth
            variant="outlined"
            size='medium'
            type='password'
            name='password'
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            sx={{ marginBottom: '10px ' }}
          />
          <Stack>
            <Typography sx={{ color: `${messageColor}`, fontSize: "0.9rem" }}>{message}</Typography>
          </Stack>
          <Stack mt={4}>
            <Button type='submit' fullWidth variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Login</Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  )
}
