import React, { useState } from 'react'
import { Button, Container, Stack, TextField, Typography, Grid } from '@mui/material'
import styled from 'styled-components'
import { Icon } from '@iconify/react'
import { JOIN_THE_MOVEMENT, SIGN_UP_HERE_TO_RECEIVE_EMAIL } from '../shared/constants/home'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { SUBSCRIBE } from '../shared/constants/APIs'
import axios from 'axios'
import SubscriptionMsg from './subscriptionMsg'

// *******************************
const JoinMovementContainer = styled(Container)({
  backgroundColor: "#89181C",
  height: '30vh',
  marginTop: "180px",
  color: '#fff',
  '@media(min-width:320px) and (max-width:900px)': {
    height: '50vh',
  }
})
const JoinMovementHeading = styled(Typography)({
  '@media(min-width:320px) and (max-width:370px)': {
    fontSize: "1.5rem"
  },
  '@media(min-width:371px) and (max-width:550px)': {
    fontSize: "1.8rem"
  },
  '@media(min-width:551px) and (max-width:900px)': {
    fontSize: "1.9rem"
  },
  '@media(min-width:901px) and (max-width:1050px)': {
    fontSize: "1.9rem"
  },
})
const HeadingGridItem = styled(Grid)({
  '@media(min-width:320px) and (max-width:663px)': {
    padding: "0px !important"
  }
})
const GridContainer = styled(Grid)({
  '@media(min-width:320px) and (max-width:663px)': {
    padding: "0px !important"
  }
})
const JoinMovement = () => {
  const [showMsg, setShowMsg] = useState(false)
  const subscribeSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: subscribeSchema,
    onSubmit: async () => {
      resetForm()
      setTimeout(function () {
        setShowMsg(true)
        setTimeout(function () {
          setShowMsg(false)
        }, 5000);
      }, 1000)
      await axios.post(SUBSCRIBE, values)
        .then((response) => {
          console.log(response)
        }, (error) => {
          console.log(error)
        })
    }
  })
  const {
    errors, touched, handleSubmit, getFieldProps, values, resetForm
  } = formik
  return (
    <JoinMovementContainer maxWidth='100%'>
      <Container maxWidth='lg' sx={{ height: "100%" }}>
        <GridContainer container sx={{ height: "100%", padding: "0px 30px" }} justifyContent='flex-start' alignItems='center' spacing={{ xs: 0, sm: 0, md: 4 }}>
          <HeadingGridItem item xs={12} sm={12} md={7} sx={{ paddingTop: "0px !important", paddingRight: '100px' }}>
            <Stack sx={{ height: "100%" }} mt={{ xs: 0, sm: 0, md: -1 }} spacing={1}>
              <JoinMovementHeading variant='h2' sx={{ fontSize: "2.4rem", fontWeight: 500, textTransform: "uppercase" }}>{JOIN_THE_MOVEMENT}</JoinMovementHeading>
              <Typography variant='body1' sx={{ fontSize: "1rem", fontWeight: 300 }}>
                {SIGN_UP_HERE_TO_RECEIVE_EMAIL}
              </Typography>
            </Stack>
          </HeadingGridItem>
          <Grid item xs={12} sm={8} md={5} sx={{ paddingTop: "0px !important" }}>
            <FormikProvider value={formik} style={{ width: "100%" }}>
              <Form
                id='form'
                autoComplete='off'
                noValidate
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                <Stack spacing={2} mt={{ xs: -5, sm: -5, md: 0 }}>
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                    }}
                    type='text'
                    name='email'
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    variant='standard'
                    placeholder='ENTER YOUR EMAIL ADDRESS'
                    sx={{ backgroundColor: "#fff", padding: "10px 10px" }}
                  />
                  <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                    <Button type='submit' variant='contained' color='secondary' sx={{ padding: "8px 40px", fontSize: '1rem', fontWeight: 600 }}>Submit</Button>
                    <Icon icon="mdi:heart-outline" color="#fff" height='30px' width='30px' />
                  </Stack>
                </Stack>
              </Form>
            </FormikProvider>
          </Grid>
        </GridContainer>
      </Container>
      {showMsg && <SubscriptionMsg />}
    </JoinMovementContainer>
  )
}

export default JoinMovement