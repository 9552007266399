import React, { useState } from 'react'
import { Grid } from '@mui/material'
import Banner from '../components/banner'
import Page from '../components/helmet'
import JoinMovement from '../components/joinMovement'
import JoinUsForm from '../components/joinUs/joinUsForm'
import axios from 'axios'
import { JOIN_US } from '../shared/constants/APIs'
import Loading from '../components/loading'
const JoinUs = () => {
  const [showSpinner, setShowSpinner] = useState(false)
  const saveJoinUsHandler = async (values) => {
    const {
      email,
      fullName,
      fatherName,
      dateOfBirth,
      homeAddress,
      cnic,
      currentCity,
      contactNumber,
      bloodGroup,
      joinUsAsAVolunteer,
      officialModerator,
      projectsCoordinators,
      joinUsAsABloodDonor } = values
    setShowSpinner(true)
    const joinAsA = [];
    if (joinUsAsAVolunteer) {
      joinAsA.push("Volunteer");
    }
    if (officialModerator) {
      joinAsA.push("Moderator");
    }
    if (projectsCoordinators) {
      joinAsA.push("Project Coordinator");
    }
    if (joinUsAsABloodDonor) {
      joinAsA.push("Blood Donor");
    }
    const params = {
      email,
      fullName,
      fatherName,
      dateOfBirth,
      homeAddress,
      cnic,
      currentCity,
      contactNumber,
      bloodGroup,
      joinAsA
    }
    await axios.post(JOIN_US, params)
      .then((response) => {
        setShowSpinner(false)
        if(response){}
      }, (error) => {
        setShowSpinner(false)
        console.log(error)
      })
  }
  return (
    <Page title='BDAP | Join Us'>
      <Banner {...{ BannerTitle: "Join Us" }} />
      <Grid container justifyContent='center' alignItems='center' sx={{ margin: "100px 0px" }}>
        <Grid item xs={12} sm={8} md={5}>
          <JoinUsForm {...{ saveJoinUsHandler }} />
          <Loading {...{ showSpinner }} />
        </Grid>
      </Grid>
      <JoinMovement />
    </Page>
  )
}

export default JoinUs