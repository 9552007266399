import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Icon } from '@iconify/react';
import { Stack, Typography } from '@mui/material';


const ITEM_HEIGHT = 48;

export default function MenuAction({ editHandler, row, deleteHandler, hideEditAction }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editAction = () => {
    editHandler(row)
    handleClose()
  }
  const deleteAction = () => {
    deleteHandler(row)
    handleClose()
  }
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon icon="carbon:overflow-menu-vertical" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '15ch',
            borderRadius: '5px',
            marginLeft: "-70px"
          },
        }}
      >
        {!hideEditAction && <MenuItem onClick={editAction}>
          <Stack sx={{ width: "100%" }} direction='row' justifyContent='space-between' alignItems='center'>
            <Typography>Edit</Typography>
            <Icon icon="material-symbols:edit-square-outline" width='18px' height='18px' />
          </Stack>
        </MenuItem>}
        <MenuItem onClick={deleteAction}>
          <Stack sx={{ width: "100%" }} direction='row' justifyContent='space-between' alignItems='center'>
            <Typography>Delete</Typography>
            <Icon icon="material-symbols:delete-outline" width='18px' height='18px' />
          </Stack>
        </MenuItem>
      </Menu>
    </div>
  );
}