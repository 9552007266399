import React from 'react'
import Banner from '../components/banner'
import Page from '../components/helmet'
import AboutContent from '../components/about/about'
import { Container } from '@mui/material'
import JoinMovement from '../components/joinMovement'
import styled from 'styled-components'

const AboutContainer = styled(Container)({
  '@media(min-width:320px) and (max-width:900px)': {
    padding: '0px 20px !important'
  }
})

const About = () => {
  return (
    <Page title='BDAP | About'>
      <Banner {...{ BannerTitle: "About Us" }} />
      <AboutContainer maxWidth='lg' sx={{ marginTop: "100px", padding: "0px 80px !important" }}>
        <AboutContent />
      </AboutContainer>
      <JoinMovement />
    </Page>
  )
}

export default About