const PRIMARY = {
  main: '#89181C',
  dark: '#89181C',
}
const SECONDARY = {
  main: '#05562E',
  dark: '#05562E',
}


const palette = {
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
}

export default palette
