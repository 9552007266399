import React, { useState, useEffect } from "react"
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import axios from 'axios'
import { Container, Stack, Typography } from "@mui/material"
import Loading from '../components/loading'
import ProjectsTable from "../components/admin/projects/projectsTable"
import { GET_PROJECTS, UPDATE_PROJECT, DELETE_PROJECT } from '../shared/constants/APIs'

axios.defaults.withCredentials = true;
const AdminProjects = () => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false)
  const [allProjects, setAllProjects] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [showFormDialog, setShowFormDialog] = useState(false)
  const [page, setPage] = useState(0);
  useEffect(() => {
    const getAllProjects = async () => {
      const params = {
        isAdmin: true
      }
      setShowSpinner(true)
      await axios.post(GET_PROJECTS, params)
        .then((response) => {
          setAllProjects([...response.data.data])
          setTotalCount(response.data.total)
          setShowSpinner(false)
        }, (error) => {
          if (error) {
            navigate('/login')
          }
          setShowSpinner(false)
          console.log(error)
        })
    }
    getAllProjects()
  }, [navigate])

  const LoadMoreHandler = async () => {
    setShowSpinner(true)
    const lastItem = allProjects.slice(-1)
    const params = {
      startAt: lastItem[0].id
      // search: searchValue === '' ? undefined : searchValue
    }
    await axios.post(GET_PROJECTS, params)
      .then((response) => {
        if (response.data.data.length === 0) {
          // setHideLoadButton(true)
        } else {
          setAllProjects([...allProjects, ...response.data.data])
        }
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  const refreshAllProjects = async () => {
    const params = {
      isAdmin: true
    }
    setShowSpinner(true)
    await axios.post(GET_PROJECTS, params)
      .then((response) => {
        setAllProjects([...response.data.data])
        setTotalCount(response.data.total)
        setShowSpinner(false)
      }, (error) => {
        if (error) {
          navigate('/login')
        }
        setShowSpinner(false)
        console.log(error)
      })
    setPage(0)
  }
  const editProjectHandler = async (values, id) => {
    const params = {
      ...values,
      id
    }
    await axios.post(UPDATE_PROJECT, params)
      .then((response) => {
        if (response) { }
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
    setAllProjects([])
    refreshAllProjects()
    setShowFormDialog(false)
  }
  const deleteHandler = async (item) => {
    setShowSpinner(true)
    const { id } = item
    const params = { id }
    await axios.delete(DELETE_PROJECT, { data: params })
      .then((response) => {
        if (response) { }
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
    setAllProjects([])
    refreshAllProjects()
    setShowFormDialog(false)
  }

  return (
    <div>
      <Container maxWidth='lg' sx={{ marginTop: "40px" }}>
        <Stack justifyContent='center' alignItems='center' mb={5}>
          <Typography variant='h6' sx={{ fontSize: "2rem" }}>Projects</Typography>
        </Stack>
        <Stack justifyContent='flex-end' alignItems='flex-end' mb={5}>
          <Typography component={RouterLink} to='/s/add-project' variant='h6' sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#89181c" }}>+ Add Project</Typography>
        </Stack>
        <ProjectsTable {...{ setPage, page, setShowFormDialog, showFormDialog, allProjects, LoadMoreHandler, totalCount, editProjectHandler, deleteHandler }} />
        <Loading {...{ showSpinner }} />
      </Container>
    </div>
  )
}

export default AdminProjects