
import Styled, { keyframes } from 'styled-components'
import { Box } from '@mui/system';
import { Icon } from '@iconify/react';
import { Stack, Typography } from '@mui/material';

const fadeIn = keyframes`
  0% {
    width:100%;
  }
  100% {
    width:0%;
  }
`
const MsgContainer = Styled(Box)`
  position:fixed;
  right:10px;
  top:100px;
  background:#fff;
  color:#000;
  width:370px;  
  z-index:20000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`
const MsgBox = Styled(Box)`
  position:relative;
  display:flex;
  justify-content:space-between;
  align-items:center;
  height:100%;
  padding:0px 20px
`

const AnimationBar = Styled(Box)`
  height:5px;
  width:0%;
  position:absolute;
  top:0;
  right:0;
  // background:url(../assets/home/banner.webp);
  // background-size:100%;
  background-color:#89181C;
  animation: 5s ${fadeIn} ease-out;
`

const subscriptionMsg = () => {
  return (
    <div>
      <MsgContainer>
        <MsgBox>
          <AnimationBar></AnimationBar>
          <Stack spacing={2} sx={{ width: "fit-content !important" }} mt={2} mb={2}>
            <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>You'll be in touch</Typography>
            <Typography variant='body' sx={{ fontWeight: 200, width: "85%", fontSize: "0.8rem" }}>Thank you for contacting <strong style={{ color: "#89181C", fontSize: ".9rem", fontWeight: 600 }}>BDAP</strong>  Support Team. We got your message and will respond to you ASAP.</Typography>
          </Stack>
          {/* <Icon icon="akar-icons:check" color="green" width='30' height='30' /> */}
          <Icon icon="emojione:open-mailbox-with-raised-flag" width='50' height='50' />
        </MsgBox>
      </MsgContainer>
    </div>
  )
}

export default subscriptionMsg