import React from 'react'
import Routes from './routes'
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '../theme/GlobalStyles';
import { HelmetProvider } from 'react-helmet-async';
import HelmetPage from '../components/helmet';
import ScrollToTop from '../components/scrollToTop';
import ThemeConfig from '../theme'
// ----------------------------------------------------------------------

const App = () => {
  return (
    <ThemeConfig>
      <HelmetProvider>
        <HelmetPage />
        <GlobalStyles />
        <ScrollToTop />
        <Routes />
      </HelmetProvider>
    </ThemeConfig>
  )
}
export default App