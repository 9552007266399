import React from 'react'
import { useRoutes } from 'react-router-dom'
import NavBar from '../layout/navBar'
import LogoOnlyLayout from '../layout/logoOnlyLayout'
import Contact from '../pages/contact'
import Home from '../pages/home'
import Login from '../pages/login'
import Donors from '../pages/donors'
import BloodRequest from '../pages/bloodRequest'
import JoinUs from '../pages/joinUs'
import About from '../pages/about'
import Donate from '../pages/donate'
import OurProjects from '../pages/ourProjects'
// ************** AdminRoutes *********************
import AdminLayout from '../layout/adminLayout'
import Addproject from '../pages/addProject'
import AdminDonors from '../pages/adminDonors'
import AdminBloodRequests from '../pages/adminBloodRequest'
import User from '../pages/user'
import AdminProjects from '../pages/adminProjects'
import BloodRequestForm from '../pages/bloodRequestForm'
import AddDonor from '../pages/addDonor'
// ---------------------------------------------------------------------

const Router = () => {
  return useRoutes([
    {
      path: '/',
      element: <NavBar />,
      children: [
        { path: '/', element: <Home /> },
        { path: 'contact-us', element: <Contact /> },
        // { path: '/login', element: <Login /> },
        { path: 'blood-donors', element: <Donors /> },
        { path: 'blood-requests', element: <BloodRequest /> },
        { path: 'blood-request', element: <BloodRequestForm /> },
        { path: 'join-us', element: <JoinUs /> },
        { path: 'about-us', element: <About /> },
        { path: 'our-projects', element: <OurProjects /> },
        { path: 'donate', element: <Donate /> },
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/login', element: <Login /> },
      ]
    },
    {
      path: '/s',
      element: <AdminLayout />,
      children: [
        { path: 'admin-blood-donors', element: <AdminDonors /> },
        { path: 'admin-blood-requests', element: <AdminBloodRequests /> },
        { path: 'admin-our-projects', element: <AdminProjects /> },
        { path: 'add-project', element: <Addproject /> },

        { path: 'admin-user', element: <User /> },
        { path: 'admin-add-donor', element: <AddDonor /> },

      ]
    }
  ])
}
export default Router
