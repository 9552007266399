import React from 'react'
import {
  TextField, Stack, Typography, Button, Card
} from '@mui/material'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'

export default function AddDonorForm({ saveDonorHandler, message }) {

  const addDonorSchema = Yup.object().shape({
    fullName: Yup.string()
      .required('Full  name is required'),
    bloodGroup: Yup.string()
      .required('Blood group is required'),
    contact: Yup.string()
      .required('Contact number is required'),
    city: Yup.string()
      .required('City name is required'),

  })
  const formik = useFormik({
    initialValues: {
      bloodGroup: '',
      city: '',
      fullName: '',
      contact: '',
    },
    validationSchema: addDonorSchema,
    onSubmit: () => {
      saveDonorHandler(values)
      resetForm()
    }
  })

  const {
    errors, touched, handleSubmit, getFieldProps, values, resetForm
  } = formik
  const fieldTextStyle = {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: "3px"
  }
  return (

    <Card sx={{ padding: '2rem 2rem 3rem 2rem', borderRadius: '5px' }}>
      <Stack mb={1}>
        <Typography variant='h4' sx={{ fontSize: "1.8rem", fontWeight: 600, textAlign: "center" }}>Add Donor</Typography>
      </Stack>
      {message && <Stack mb={1} mt={1}>
        <Typography variant='h4' color='secondary' sx={{ fontSize: "0.9rem", fontWeight: 500, textAlign: "center" }}>{message}</Typography>
      </Stack>}
      <FormikProvider value={formik}>
        <Form
          id='form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Stack spacing={2} sx={{ width: "100%" }} direction={{ xs: "column", sm: "column", md: 'row' }} justifyContent='space-between' alignItems='center'>
            <Stack sx={{ width: "100%" }} >
              <Typography variant='body1' sx={fieldTextStyle}>Contact Person Name</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='fullName'
                {...getFieldProps('fullName')}
                error={Boolean(touched.fullName && errors.fullName)}
                helperText={touched.fullName && errors.fullName}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Contact</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='contact'
                {...getFieldProps('contact')}
                error={Boolean(touched.contact && errors.contact)}
                helperText={touched.contact && errors.contact}
                sx={{ marginBottom: '10px ' }}
              />
              <Typography variant='body1' sx={fieldTextStyle}>Blood group</Typography>
              <TextField
                fullWidth
                size='medium'
                type='text'
                name='bloodGroup'
                {...getFieldProps('bloodGroup')}
                error={Boolean(touched.bloodGroup && errors.bloodGroup)}
                helperText={touched.bloodGroup && errors.bloodGroup}
                sx={{ marginBottom: '10px ' }}
              />

            </Stack>
          </Stack>
          <Stack sx={{ width: "100%" }} >
            <Typography variant='body1' sx={fieldTextStyle}>City</Typography>
            <TextField
              fullWidth
              size='medium'
              type='text'
              name='city'
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
              sx={{ marginBottom: '10px ' }}
            />
          </Stack>
          <Stack mt={4} direction='row' spacing={1}>
            <Button type='submit' fullWidth variant='contained' sx={{ fontSize: '20px', fontWeight: 600 }}>Save</Button>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  )
}
