import React from 'react'
import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import { Icon } from '@iconify/react'
const Line = styled(Box)({
  width: "100px",
  height: "5px",
  borderRadius: '100px',
  backgroundColor: "#89181C"
})
const HeartAndline = ({ showRightLine }) => {
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
      {showRightLine && <Line />}
      <Icon icon="mdi:heart-outline" color="#89181C" height='40px' width='40px' />
      <Line />
    </Stack>
  )
}

export default HeartAndline