import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'
import ProjectForm from '../../projects/projectForm';
import { Dialog, DialogContent } from '@mui/material';
import MenuAction from '../menuItem';
// ******************************************************

const columns = [
  { id: 'title', label: 'Title' },
  { id: 'description', label: 'Description' },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'imgUrl',
    label: 'GDrive Image ID'
  },
  {
    id: 'startDate',
    label: 'Start Date'
  },
  {
    id: 'action',
    label: 'Actions'
  }
];
export default function ProjectsTable({ setPage, page, setShowFormDialog, showFormDialog, allProjects, LoadMoreHandler, totalCount, editProjectHandler, deleteHandler }) {

  const rowsPerPage = 12;
  const [project, setProject] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    LoadMoreHandler()
  };


  const editHandler = (project) => {
    setShowFormDialog(true)
    setProject(project)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', padding: "20px 30px" }}>
      <TableContainer sx={{}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, overflow: "hidden !important" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allProjects && allProjects?.length > 0 && allProjects
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover role="checkbox"
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer", overflow: "hidden !important" }}
                  >
                    <TableCell sx={{ maxWidth: 200 }}> {row.title}</TableCell>
                    <TableCell sx={{
                      whiteSpace: 'nowrap',
                      maxWidth: '350px',
                      overflow: 'hidden',
                      textOverflow: "ellipsis !important",
                    }}>{row.description}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell sx={{ maxWidth: 100, textOverflow: "ellipsis !important", overflow: 'hidden', whiteSpace: 'nowrap' }}>{row.imageUrl}</TableCell>
                    <TableCell sx={{ minWidth: 100 }}>{row.startDate}</TableCell>
                    <TableCell ><MenuAction {...{ editHandler, row, deleteHandler, index }} /></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions=''
        component="div"
        count={totalCount && totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
      {
        showFormDialog &&
        <Dialog
          fullWidth={true}
          maxWidth='sm'
          open={showFormDialog}
          onClose={() => setShowFormDialog(!showFormDialog)}
        >
          <DialogContent sx={{ '::-webkit-scrollbar': { width: "3px !important" }, '::-webkit-scrollbar-thumb': { height: "10px !important", background: "rgba(0,0,0,0.3)", }, padding: '0 0rem 0rem 0rem', borderRadius: '5px' }}>
            <ProjectForm {...{ project, editProjectHandler, setShowFormDialog }} />
          </DialogContent>
        </Dialog>

      }
    </Paper>
  );
}