import React, { useState } from 'react'
import Styled from 'styled-components'
import { Container, Stack, Box, Button, Typography } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import { NavLink, Outlet, Link as RouterLink } from 'react-router-dom'
import { Icon } from '@iconify/react'
import './nav.css'
import Footer from './footer'
import BDAPLogo from './BDAP-Logo.webp'

const NavLinksWrapper = Styled(Container)({
  '@media(min-width:320px) and (max-width:1200px)': {
    width: '100% !important',
    padding: "0 !important"
  }
})
const BarsIcon = Styled(Icon)({
  display: 'none',
  '@media(min-width:320px) and (max-width:1200px)': {
    height: '100%',
    width: '60px',
    display: 'block',
    position: 'absolute',
    left: '13px',
    top: -2,
    // height: '85px'
  }
})
const CrossIcon = Styled(Icon)({
  display: 'none',
  '@media(min-width:320px) and (max-width:1200px)': {
    display: 'block',
    position: 'absolute',
    left: '20px',
    top: '30px',
    botom: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    zIndex: 200,
    width: '40px',
    height: '40px',
    color: "#FF9A23"
  }
})
const NavLinksContainer = Styled(Stack)({
  '@media(min-width:320px) and (max-width:1200px)': {
    position: 'absolute',
    background: '#FFFFFF',
    top: '0px',
    // width: '-100% ',
    left: '0',
    zIndex: 100,
    height: '100vh !important',
    display: 'flex',
    // display: 'none',z
    justifyContent: 'center',
    alignItems: 'center',
    // paddingLeft: "74px !important",
    borderRight: 'solid #89181C 3px',
    borderBottom: 'solid #89181C 3px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    width: "335px",
    '@media(min-width:320px) and (max-width:450px)': {
      width: "80vw",
    }
  }
})
const ShowInMobileLogo = Styled(CardMedia)({
  display: 'none',
  '@media(min-width:320px) and (max-width:1200px)': {
    display: 'block',
    marginTop: '11px'
  }
})
const Logo = Styled(CardMedia)({
  '@media(min-width:320px) and (max-width:1200px)': {
    display: 'none',
  }
})
const LogoLink = Styled(NavLink)({
  '@media(min-width:320px) and (max-width:1200px)': {
    display: 'none',
  }
})
const NavContainer = Styled('header')({
  height: '100px',
  background: '#fff',
  zIndex: 1000,
  padding: '0',
  width: "100%",
  '@media(min-width:320px) and (max-width:1200px)': {
    width: '100% !important',
    marginTop: '0px !important',
    borderRadius: '0',
    position: 'relative',
    height: '120px',
    top: '0',
    left: 0,
    right: 0,
    marginLeft: '0',
    marginRight: '0'
  }

})
const Links = Styled(NavLink)({
  textDecoration: 'none',
  color: '#000000',
  fontWeight: '600 !important',
  fontSize: '1rem',
  textTransform: "capitalize",
  marginLeft: "24px !important",
  '@media(min-width:320px) and (max-width:1200px)': {
    fontSize: "18px",
    marginLeft: "0px !important",
  },
  '@media(min-width:901px) and (max-width:970px)': {
    marginLeft: "20px !important",
  }
})
const HomeLink = Styled(NavLink)({
  display: "none",
  '@media(min-width:320px) and (max-width:1200px)': {
    display: "block",
    fontSize: '18px',
    color: "#000",
    textDecoration: 'none',
    fontWeight: '600 !important',
    textTransform: "capitalize",
    marginLeft: "-12px"
  }
})
const TopBackground = Styled(Box)({
  height: '37px',
  backgroundColor: "#89181C",
  width: "100%"
})

const Navbar = () => {
  const [joinUsFormDialog, setJoinUsFormDialog] = useState(false)
  const barsHandler = () => {
    let bars = document.getElementById('NavLinksContainer')
    let crossIcon = document.getElementById('CrossIcon')
    let menubar = document.getElementById('menuBars')
    menubar.classList.toggle("showBars")
    crossIcon.classList.toggle("crossAnimate")
    bars.classList.toggle("showMenu")
  }
  const joinUsDialogHandler = () => {
    let bars = document.getElementById('NavLinksContainer')
    let crossIcon = document.getElementById('CrossIcon')
    let menubar = document.getElementById('menuBars')
    menubar.classList.toggle("showBars")
    crossIcon.classList.toggle("crossAnimate")
    bars.classList.toggle("showMenu")
    setJoinUsFormDialog(!joinUsFormDialog)
  }

  return (
    <>
      <NavContainer maxWidth='100%' id='header'>
        <TopBackground>
          <Stack component={RouterLink} to='our-projects' sx={{ height: '100%', textDecoration: "none" }} justifyContent='center' alignItems='center'>
            <Typography sx={{ fontSize: '13px', color: "#fff", fontWeight: 600 }}>RUNNING CURRENT CAMPAIGN</Typography>
          </Stack>
        </TopBackground>
        <BarsIcon icon="bx:menu-alt-left" onClick={barsHandler} id='menuBars' />
        <NavLinksWrapper maxWidth='lg' sx={{ padding: "0px 10px !important" }}>
          <NavLinksContainer sx={{ height: "80px" }} id='NavLinksContainer' direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} justifyContent='space-between' alignItems="center" >
            <CrossIcon color='#89181C' id='CrossIcon' icon="fluent-emoji-high-contrast:cross-mark" onClick={barsHandler} />
            <LogoLink onClick={barsHandler} to='/'>
              <Logo
                component="img"
                image={BDAPLogo}
                alt="nav image"
                sx={{ width: 'fit-content', height: '50px', cursor: 'pointer', objectFit: 'fill', marginLeft: '24px', marginBottom:'21px' }}
              />
            </LogoLink>
            <Stack sx={{ width: "100%" }} mt={-2} ml={{ xs: 0, sm: 0, md: 0, lg: 6 }} mr={{ xs: 0, sm: 0, md: 0, lg: 6 }} spacing={{ xs: 2, sm: 2, md: 2, lg: 5 }} direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} justifyContent='space-between' alignItems='center'>
              <HomeLink onClick={barsHandler} id='navLink' to='/' activeClassName="active">Home</HomeLink>
              <Links onClick={barsHandler} id='navLink' to='blood-donors'>Donors</Links>
              <Links onClick={barsHandler} id='navLink' to='blood-requests'>Blood Requests</Links>
              <Links onClick={barsHandler} id='navLink' to='our-projects' activeClassName="active">Our Projects</Links>
              <Links onClick={barsHandler} id='navLink' to='about-us'>About Us</Links>
              <Links onClick={barsHandler} id='navLink' to='contact-us'>Contact</Links>
              <Links onClick={joinUsDialogHandler} id='navLink' to='join-us' >join us</Links>
              <Stack spacing={1} direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}>
                <Button component={RouterLink} to='blood-request' variant='outlined' color='primary' sx={{ border: "1px solid rgba(137, 24, 28, 1)", color: "#89181C" }}> Request For Blood</Button>
                <Button component={RouterLink} to='donate' variant='contained' color='secondary'>Donate</Button>
              </Stack>
            </Stack>
          </NavLinksContainer>
          <Stack justifyContent='center' alignItems='center' component={RouterLink} to='/'>
            <ShowInMobileLogo
              component='img'
              image={BDAPLogo}
              alt="nav image"
              sx={{ width: 'initial', height: '60px', cursor: 'pointer', objectFit: 'fill', marginLeft: '24px', marginBottom:'21px' }}
            />
          </Stack>
        </NavLinksWrapper>
      </NavContainer>
      <Outlet />
      <Footer />
    </>
  )
}

export default Navbar
