import { Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import CenterHeading from '../centerHeading'
import HeartAndLine from '../heartAndline'
import JoinMovement from '../joinMovement'
import { Link as RouterLink } from 'react-router-dom'
import { CLICK_HEARE_TO_REQUEST_FOR_BLOOD_BUTTON, WE_LOVE_TO_HEAR, NUMBER, WE_LOVE_TO_HEAR_CONTAINER_PARA_1, WE_LOVE_TO_HEAR_CONTAINER_PARA_2 } from '../../shared/constants/contact'
import styled from 'styled-components'

const ContactContent = styled(Typography)({
  '@media(min-width:320px) and (max-width:900px)': {
    padding: '0px 0px !important'
  }
})
const BottomButton = styled(Button)({
  '@media(min-width:320px) and (max-width:370px)': {
    padding: "10px !important",
    fontSize: ".7rem",
    textAlign: "center"
  },
  '@media(min-width:371px) and (max-width:451px)': {
    padding: "15px 20px !important",
    fontSize: ".7rem"
  }
})
const WeLoveTo = () => {
  return (
    <>
      <Container>
        <Stack justifyContent='center' alignItems='center' mt={12} spacing={3}>
          <Stack style={{ width: "fit-content" }}>
            <HeartAndLine {...{ showRightLine: true }} />
          </Stack>
          <CenterHeading {...{ heading: WE_LOVE_TO_HEAR }} />
          <ContactContent variant='body1' sx={{ fontSize: '1.1rem', fontWeight: '400 !important', textAlign: "center", padding: "0px 100px" }}>
            {WE_LOVE_TO_HEAR_CONTAINER_PARA_1}
            <Typography variant='subtitle1' style={{ textDecoration: "underline", color: "#89181C", height: "fit-content", marginTop: "15px", marginBottom: "-15px", fontSize: '1.12rem' }}>{NUMBER}</Typography><br />
            {WE_LOVE_TO_HEAR_CONTAINER_PARA_2}
          </ContactContent>
          <BottomButton component={RouterLink} to='/donate' variant='contained' sx={{ padding: "15px 30px", marginTop: "60px !important" }}>{CLICK_HEARE_TO_REQUEST_FOR_BLOOD_BUTTON}</BottomButton>
        </Stack>
      </Container>
      <JoinMovement />
    </>
  )
}

export default WeLoveTo