import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Container, Stack, Typography } from '@mui/material'
import BloodRequestTable from '../components/admin/bloodRequest/bloodRequestTabel'
import Loading from '../components/loading'
import { GET_ADMIN_BLOOD_REQUESTS, DELETE_BLOOD_REQUEST, UPDATE_ADMIN_BLOOD_REQUEST } from '../shared/constants/APIs'

// ******************************************
axios.defaults.withCredentials = true;
const AdminDonors = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [bloodRequests, setbloodRequests] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [showFormDialog, setShowFormDialog] = useState(false)
  useEffect(() => {
    const getAllBloodRequests = async () => {
      setShowSpinner(true)
      await axios.post(GET_ADMIN_BLOOD_REQUESTS)
        .then((response) => {
          setTotalCount(response.data.total)
          setbloodRequests([...response.data.data])
          setShowSpinner(false)
        }, (error) => {
          console.log(error)
          setShowSpinner(false)
          if (error) {
            navigate('/login')
          }
        })
    }
    getAllBloodRequests()
  }, [navigate])

  const LoadMoreHandler = async () => {
    setShowSpinner(true)
    const lastItem = bloodRequests.slice(-1)
    const params = {
      startAt: lastItem[0].id,
      search: searchValue === '' ? undefined : searchValue
    }
    await axios.post(GET_ADMIN_BLOOD_REQUESTS, params)
      .then((response) => {
        if (response) { }
        if (response.data.data.length > 0) {
          setbloodRequests([...bloodRequests, ...response.data.data])
          setTotalCount(response.data.total)
        }
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  const searchHandler = async (value) => {
    setShowSpinner(true)
    setSearchValue(value)
    const params = {
      search: value === '' ? undefined : value
    }
    await axios.post(GET_ADMIN_BLOOD_REQUESTS, params)
      .then((response) => {
        setbloodRequests([...response.data.data])
        setTotalCount(response.data.total)
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
  }
  const refreshBloodRequests = async () => {
    setShowSpinner(true)
    await axios.post(GET_ADMIN_BLOOD_REQUESTS)
      .then((response) => {
        setTotalCount(response.data.total)
        setbloodRequests([...response.data.data])
        setShowSpinner(false)
      }, (error) => {
        if (error) {
          navigate('/login')
        }
        setShowSpinner(false)
        console.log(error)
      })
    setPage(0)
  }
  const deleteHandler = async (item) => {
    const { id } = item
    const params = { id }
    await axios.delete(DELETE_BLOOD_REQUEST, { data: params })
      .then((response) => {
        if (response) { }
        setShowSpinner(false)
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
    setbloodRequests([])
    refreshBloodRequests()
    setShowFormDialog(false)
  }
  const editBloodRequestHandler = async (values, id) => {
    const params = {
      ...values,
      id
    }
    await axios.post(UPDATE_ADMIN_BLOOD_REQUEST, params)
      .then((response) => {
        setShowSpinner(false)
        if (response) { }
      }, (error) => {
        console.log(error)
        setShowSpinner(false)
      })
    setbloodRequests([])
    refreshBloodRequests()
    setShowFormDialog(false)
  }
  return (
    <Container maxWidth='lg' sx={{ marginTop: "40px" }}>
      <Stack justifyContent='center' alignItems='center' mb={5}>
        <Typography variant='h6' sx={{ fontSize: "2rem" }}>Blood Requests</Typography>
      </Stack>
      <BloodRequestTable {...{ setPage, page, setShowFormDialog, showFormDialog, bloodRequests, searchHandler, LoadMoreHandler, deleteHandler, totalCount, editBloodRequestHandler }} />
      <Loading {...{ showSpinner }} />
    </Container>
  )
}

export default AdminDonors