import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditForm from './editForm'
import { Grid, Stack } from '@mui/material';
import Search from '../../search';
import MenuAction from '../menuItem';
import EmptyTable from '../../emptyTable';

// ******************************************************

const columns = [
  { id: 'contactPerson', label: 'Contact Person', minWidth: 100 },
  { id: 'bloodGroup', label: 'Blood Group', minWidth: 100 },
  {
    id: 'hospital',
    label: 'Hospital',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    align: 'left'
  },
];
export default function BloodRequestTable({ setPage, page, LoadMoreHandler, showFormDialog, setShowFormDialog, bloodRequests, searchHandler, deleteHandler, totalCount, editBloodRequestHandler }) {


  const rowsPerPage = 10
  const [donorData, setDonorData] = useState()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    LoadMoreHandler()
  };


  const editHandler = (donor) => {
    setShowFormDialog(true)
    setDonorData(donor)
  }

  return (
    <>
      {!showFormDialog ? <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', padding: "20px 30px" }}>
        <Stack sx={{ maxWidth: 440, marginBottom: "30px", transition: "all 0.3s" }}>
          <Search {...{ placeholder: "Search with blood group or city", searchHandler }} />
        </Stack>
        <TableContainer sx={{}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bloodRequests && bloodRequests?.length > 0 && bloodRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover role="checkbox"
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                    // onClick={() => editHandler(row)}
                    >
                      <TableCell component="th" scope="row">
                        {row.contactPersonName}
                      </TableCell>
                      <TableCell align="left">{row.bloodGroup}</TableCell>
                      <TableCell align="left">{row.hospital}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.date}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align='right'>
                        <MenuAction  {...{ editHandler, row, deleteHandler, index }} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {bloodRequests.length === 0 && (
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align='center' colSpan={6} sx={{ py: 3 }}>
                    <EmptyTable message='No blood request found' />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions=''
          component="div"
          count={totalCount && totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />

      </Paper> :
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={12} md={6}>
            <EditForm {...{ setShowFormDialog, showFormDialog, donorData, editBloodRequestHandler }} />
          </Grid>
        </Grid>
      }
    </>
  );
}